import React, { useState, useEffect } from 'react'
import {
  Typography,
  Button,
  Box,
  Divider,
  Step,
  StepLabel,
  Stack,
  Slide,
  Fade,
  Skeleton,
} from '@mui/material'

import LockIcon from '@mui/icons-material/Lock'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import {
  CardContentStyled,
  CardMediaStyled,
  DescriptionTypography,
  LevelStepper,
  Overlay,
  Progress,
  StyledCard,
  TitleTypography,
} from './styles'
import { ComingSoomCardProps } from './types'
import comingSoonTag from '../../assets/images/comingsoontag.png'

const ComingSoonCard: React.FC<ComingSoomCardProps> = ({
  loading,
  image,
  title,
  description,
}) => {
  const [slideIn, setSlideIn] = useState(false)
  const [fadeIn, setFadeIn] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setSlideIn(true)
      setFadeIn(true)
    }, 300)
  }, [])

  return (
    <Fade in={fadeIn} timeout={1000}>
      <Slide in={slideIn} direction='down' timeout={800}>
        <StyledCard>
          {!loading && (
            <img
              src={comingSoonTag}
              alt='Coming Soon'
              style={{
                position: 'absolute',
                top: '-5px', // Adjust as needed
                right: '-5px', // Adjust as needed
                width: '80px', // Adjust size as needed
                zIndex: 10, // Ensure it stays on top
                opacity: 0.8, // Adjust transparency
              }}
            />
          )}
          {loading ? (
            <Skeleton sx={{ bgcolor: 'grey.800', mx: 2 }} height={'160px'} />
          ) : (
            <CardMediaStyled image={image}>
              <Overlay />
            </CardMediaStyled>
          )}

          <CardContentStyled>
            <TitleTypography variant='h6'>
              {loading ? <Skeleton sx={{ bgcolor: 'grey.800' }} /> : title}
            </TitleTypography>
            <DescriptionTypography variant='body2'>
              {loading ? (
                <Skeleton sx={{ bgcolor: 'grey.800' }} />
              ) : (
                description
              )}
            </DescriptionTypography>

            <Box mt={1}>
              <Typography variant='subtitle2' align='center'>
                {loading ? (
                  <Skeleton sx={{ bgcolor: 'grey.800' }} />
                ) : (
                  <Typography>Levels Unveiling Soon</Typography>
                )}
              </Typography>
              <Box mt={0.5}>
                {loading ? (
                  <Skeleton sx={{ bgcolor: 'grey.800' }} height={50} />
                ) : (
                  <Progress variant='determinate' value={100} />
                )}
              </Box>
            </Box>

            {loading ? (
              <Skeleton sx={{ bgcolor: 'grey.800' }} />
            ) : (
              <LevelStepper alternativeLabel>
                {[...Array(5)].map((_, index) => {
                  return (
                    <Step key={index} completed={false}>
                      <StepLabel
                        icon={
                          <HourglassEmptyIcon style={{ color: '#cfcfcf' }} />
                        }
                        sx={{
                          cursor: 'default',
                          '& .MuiStepLabel-label': {
                            color: '#cfcfcf !important',
                          },
                        }}
                      >
                        <Stack alignItems={'center'}>{`Coming Soon`}</Stack>
                      </StepLabel>
                    </Step>
                  )
                })}
              </LevelStepper>
            )}

            <Divider sx={{ backgroundColor: '#444', marginY: 1.5 }} />
            <Typography variant='subtitle2' align='center'>
              {loading ? (
                <Skeleton sx={{ bgcolor: 'grey.800' }} />
              ) : (
                <>Stay tuned for the next big adventure!</>
              )}
            </Typography>

            <DescriptionTypography variant='body2'>
              {loading ? (
                <Skeleton sx={{ bgcolor: 'grey.800' }} />
              ) : (
                <Typography>The secret door will open soon... </Typography>
              )}
            </DescriptionTypography>

            <Box mt={1}>
              {loading ? (
                <Skeleton sx={{ bgcolor: 'grey.800' }} />
              ) : (
                <Button
                  variant='outlined'
                  color='secondary'
                  sx={{
                    color: '#ffffff', // Set text color to white
                    borderColor: '#ffffff', // White border to contrast with black background
                    '&.Mui-disabled': {
                      borderColor: '#aaaaaa', // A lighter color for disabled state
                      color: '#aaaaaa', // Greyish text for disabled state
                    },
                    '&:hover': {
                      borderColor: '#ffffff', // Keep white border on hover
                    },
                  }}
                  disabled
                  fullWidth
                >
                  Coming Soon
                </Button>
              )}
            </Box>

            {/* New Leaderboard Button */}
            <Box mt={1}>
              {loading ? (
                <Skeleton sx={{ bgcolor: 'grey.800' }} />
              ) : (
                <Button
                  variant='outlined'
                  color='secondary'
                  sx={{
                    color: '#ffffff', // Set text color to white
                    borderColor: '#ffffff', // White border to contrast with black background
                    '&.Mui-disabled': {
                      borderColor: '#aaaaaa', // A lighter color for disabled state
                      color: '#aaaaaa', // Greyish text for disabled state
                    },
                    '&:hover': {
                      borderColor: '#ffffff', // Keep white border on hover
                    },
                  }}
                  disabled
                  fullWidth
                  startIcon={<LockIcon />}
                >
                  View Leaderboard
                </Button>
              )}
            </Box>
          </CardContentStyled>
        </StyledCard>
      </Slide>
    </Fade>
  )
}

export default ComingSoonCard
