import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type TModalState = {
  messageModal: {
    open: boolean
    type: 'info' | 'error'
    title: string
    message: string
    buttonText: string
  }
}

export type CommonState = {
  modals: TModalState
}

const initialState: CommonState = {
  modals: {
    messageModal: {
      open: false,
      message: '',
      title: '',
      type: 'info',
      buttonText: 'Continue the Journey',
    },
  },
  
}

export const commonSlice = createSlice({
  name: 'commonSlice',
  initialState: initialState,
  reducers: {
    setModalsData: (state, action: PayloadAction<TModalState>) => {
      state.modals = { ...(state.modals || {}), ...action?.payload }
    },
    resetMessageModal: (state) => {
      state.modals.messageModal = {
        open: false,
        message: '',
        title: '',
        type: 'info',
        buttonText: 'Continue the Journey',
      }
    },
  },
})

export const { setModalsData, resetMessageModal } = commonSlice.actions

export default commonSlice.reducer
