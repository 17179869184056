import React, { FC } from 'react'
import { Typography } from '@mui/material'

interface TitleProps {
  title: string
}

const Title: FC<TitleProps> = ({ title }) => {
  return (
    <Typography
      variant='h1'
      sx={{
        textAlign: 'center',
        mt: 2,
        fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem', lg: '3rem' },
        textShadow: '0 0 15px #FFD700',
        fontFamily: '"Cinzel", serif',
        fontWeight: 700,
        letterSpacing: '2px',
        '& span': {
          display: 'inline-block',
          opacity: 0,
          position: 'relative',
        },
      }}
    >
      {title}
    </Typography>
  )
}

export default Title
