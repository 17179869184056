import { useParams } from 'react-router-dom'
import { TAuthState } from '../../store/slice/auth/authSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useSubmitRiddleMutation } from '../../api/riddleApi'
import { RiddleAnswerResponse } from '../../api/riddleApi/types'
import { showErrorAlert, showSuccessAlert } from './constants/constants'
import { useLevelHookParams } from './types'

const useLevelHook = ({ riddle_id }: useLevelHookParams) => {
  const { mysteryId, level } = useParams() // Access params
  const authState: TAuthState = useSelector(
    (state: RootState) => state.authReducer,
  )

  const [submitRiddle, { isLoading }] = useSubmitRiddleMutation()

  const onSubmit = (value: any) => {
    const formData = new FormData()

    formData.append('user_id', authState?.userId.toString())
    formData.append('mystery_id', mysteryId || '')
    formData.append('riddle_id', riddle_id?.toString() || '')
    formData.append('level_number', level || '')
    formData.append('answer', value)

    submitRiddle(formData)
      .unwrap()
      .then((data: RiddleAnswerResponse) => {
        if (data?.success) {
          showSuccessAlert()
        } else {
          showErrorAlert()
        }
      })
      .catch((error) => {
        showErrorAlert()
      })
  }

  return {
    onSubmit,
    isLoading,
  }
}

export default useLevelHook
