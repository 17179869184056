import BG1 from '../../assets/images/bg1.jpg'
import BG2 from '../../assets/images/bg2.jpg'
import BG3 from '../../assets/images/bg3.jpg'
import BG4 from '../../assets/images/bg4.jpg'
import BG5 from '../../assets/images/bg5.jpeg'
import 'react-slideshow-image/dist/styles.css'
import { Zoom } from 'react-slideshow-image'

const BackgroundImages = () => {
  const images = [BG1, BG2, BG3, BG4, BG5]

  return (
    <>
      <div
        className='slide-container'
        style={{
          position: 'fixed', // Set position to absolute so it sits behind the content
          top: 0,
          left: 0,
          width: '100%',
          minHeight: '100vh',
          overflow: 'hidden',
          zIndex: -1, // Lower z-index to send it behind the content
        }}
      >
        <Zoom
          arrows={false}
          duration={500}
          transitionDuration={20000}
          scale={1.5}
          autoplay
          infinite
        >
          {images.map((each, index) => (
            <img
              key={index}
              style={{
                width: '100%',
                height: '100vh',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                objectFit: 'cover',
              }}
              src={each}
            />
          ))}
        </Zoom>
      </div>
    </>
  )
}

export default BackgroundImages
