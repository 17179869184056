import { Box, Container, Typography, CircularProgress } from '@mui/material'
import ProfileInfoCard from './ProfileInfoCard'
import { TAuthState } from '../../store/slice/auth/authSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useGetSingleProfileQuery } from '../../api/profileApi'
import { formatDateToYYYYMMDD } from '../../utils/common'

const Profile: React.FC = () => {
  const authState: TAuthState = useSelector(
    (state: RootState) => state.authReducer,
  )

  const {
    data: profileData,
    isFetching: isProfileFetching,
    isSuccess: isProfileSuccess,
  } = useGetSingleProfileQuery({
    email: authState.email,
  })

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container maxWidth='sm'>
        <Typography
          variant='h1'
          sx={{
            fontSize: { xs: '2rem', sm: '2rem', md: '3rem', lg: '4rem' },
            mb: 2,
            textAlign: 'center',
            textShadow: '0 0 15px #FFD700',
            fontFamily: '"Cinzel", serif',
            fontWeight: 700,
            letterSpacing: '2px',
            '& span': {
              display: 'inline-block',
              opacity: 0,
              position: 'relative',
            },
          }}
        >
          Profile
        </Typography>

        {/* Loading Phase */}
        {isProfileFetching ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '300px', // Ensuring the loading spinner has space
            }}
          >
            <CircularProgress sx={{ color: '#FFD700' }} />
          </Box>
        ) : isProfileSuccess && profileData ? (
          <ProfileInfoCard
            userName={profileData?.user?.full_name}
            userEmail={profileData?.user?.email}
            userId={profileData?.user?.user_id}
            memberSince={formatDateToYYYYMMDD(profileData?.user?.created_at)}
          />
        ) : (
          <Typography variant='h6' color='error' align='center'>
            Failed to load profile data.
          </Typography>
        )}
      </Container>
    </Box>
  )
}

export default Profile
