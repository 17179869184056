import { Box, Button, keyframes, styled } from '@mui/material'
import {
  Bulb,
  Cable,
  Cover,
  Error,
  ErrorMessage,
  Frame,
  Header,
  InCover,
  Lamp,
  LampWrap,
  Light,
  MessageTitle,
  StarFifth,
  StarFourth,
  StarSec,
  StarThird,
  StyledButton,
} from './styles'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from '../../constants/routeUrls'

const PageNotFound = () => {
  const navigate = useNavigate()

  const handleNavigateHome = () => {
    navigate(routeUrls.HOME)
  }
  return (
    <>
      <Frame>
        <Header />

        <StarSec />
        <StarThird />
        <StarFourth />
        <StarFifth />

        <LampWrap>
          <Lamp>
            <Cable />
            <Cover />
            <InCover>
              <Bulb />
            </InCover>
            <Light />
          </Lamp>
        </LampWrap>

        <Error>
          <ErrorMessage>
            <MessageTitle>Mystery Not Found</MessageTitle>
            <StyledButton onClick={handleNavigateHome}>
              Go Back Home
            </StyledButton>
          </ErrorMessage>
        </Error>
      </Frame>
    </>
  )
}

export default PageNotFound
