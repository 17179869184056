import {
  Box,
  Button,
  Grow,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import InputField from '../../components/InputField'
import useLogin from './useLogin'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from '../../constants/routeUrls'
import {
  formContainerStyle,
  inputStyles,
  primaryButtonStyle,
  secondaryButtonStyle,
} from './styles'
import { useSelector } from 'react-redux'
import { TAuthState } from '../../store/slice/auth/authSlice'
import { RootState } from '../../store'

const Login = () => {
  const navigate = useNavigate()

  const navigateToSignup = () => {
    navigate(routeUrls.SIGNUP)
  }

  const authState: TAuthState = useSelector(
    (state: RootState) => state.authReducer,
  )

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { control, handleSubmit, onSubmit } = useLogin()

  return (
    <>
      <Box sx={formContainerStyle}>
        <Grow in={true} timeout={3000}>
          <Typography
            variant={isMobile ? 'h4' : 'h3'}
            sx={{
              color: '#FFD700',
              mb: 2,
              fontFamily: '"Cinzel", serif',
              fontWeight: 700,
              letterSpacing: '2px',
              textShadow: '0 0 15px #FFD700',
            }}
          >
            Welcome to RiddleEscape
          </Typography>
        </Grow>
        <Grow in={true} timeout={4000}>
          <Typography
            variant='h6'
            sx={{
              fontSize: { xs: '1.2rem', sm: '1.5rem' },
              mb: 3,
              color: '#E0E0E0',
            }}
          >
            Enter your details to embark on a thrilling adventure!
          </Typography>
        </Grow>

        <Box
          component='form'
          noValidate
          width={'100%'}
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Email Input */}
          <InputField
            control={control}
            name='email'
            placeholder='Your Email for Your Adventure...'
            fullWidth
            sx={{
              ...inputStyles,
              mb: 1,
              mt: 1,
            }}
          />

          {/* Password Input */}
          <InputField
            control={control}
            name='password'
            type='password'
            placeholder='Enter Your Magic Password...'
            fullWidth
            sx={{
              ...inputStyles,
              mb: 1,
              mt: 1,
            }}
          />

          <Slide
            direction='up'
            in={true}
            timeout={2000}
            mountOnEnter
            unmountOnExit
          >
            <LoadingButton
              type='submit'
              variant='contained'
              loading={authState.loading === 'pending'}
              sx={{
                ...primaryButtonStyle,
                mt: 2,
              }}
              fullWidth
            >
              Continue The Journey
            </LoadingButton>
          </Slide>

          <Slide
            direction='up'
            in={true}
            timeout={3000}
            mountOnEnter
            unmountOnExit
          >
            <Button
              variant='outlined'
              sx={{
                ...secondaryButtonStyle,
                mt: 2, // Margin-top for spacing from elements above
              }}
              fullWidth
              onClick={navigateToSignup}
            >
              Join the Adventure—Join Us Now!
            </Button>
          </Slide>
        </Box>
      </Box>
    </>
  )
}

export default Login
