import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS } from '../../constants/apiendpoints'
import { API_METHODS } from '../../constants/constants'
import { UserProgressApiTags } from './tags'
import { UserProgressPayload, UserProgressResponse } from './types'
import { ListingResponse } from '../types'

export const userProgressApi = createApi({
  reducerPath: 'userProgressApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    UserProgressApiTags.USERPROGRESS_CREATED,
    UserProgressApiTags.USERPROGRESS_DELETED,
    UserProgressApiTags.USERPROGRESS_MODIFIED,
    UserProgressApiTags.USERPROGRESS_SINGLE,
  ],
  endpoints: (builder) => ({
    // get user progress by mystery id
    getAllUserProgressById: builder.query<
      ListingResponse<UserProgressResponse[]>,
      UserProgressPayload
    >({
      query: (params: UserProgressPayload) => ({
        url: API_ENDPOINTS.GET_ALL_USERPROGRESS_BY_ID, // The ID is part of the URL
        method: API_METHODS.GET,
        params: params,
      }),
      providesTags: [
        UserProgressApiTags.USERPROGRESS_CREATED,
        UserProgressApiTags.USERPROGRESS_DELETED,
        UserProgressApiTags.USERPROGRESS_MODIFIED,
      ],
      transformResponse: (
        response: ListingResponse<UserProgressResponse[]>,
      ) => {
        return response
      },
    }),
  }),
})

export const { useGetAllUserProgressByIdQuery } = userProgressApi
