import { styled } from '@mui/system'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  LinearProgress,
  Stepper,
  Typography,
} from '@mui/material'

export const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: 350,
  maxWidth: 400,
  marginTop: '2rem',
  backgroundColor: '#1a1a1a',
  borderRadius: '15px',
  boxShadow: '0 8px 30px rgba(0, 0, 0, 0.7)',
  color: '#ffffff',
  overflow: 'hidden',
  position: 'relative',
}))

export const CardMediaStyled = styled(CardMedia)(({ theme }) => ({
  height: '160px',
  borderRadius: '15px 15px 0 0',
  position: 'relative',
}))

export const Overlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background:
    'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))',
  borderRadius: '15px 15px 0 0',
}))

export const CardContentStyled = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}))

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Merriweather, serif',
  color: '#f0e68c',
  textShadow: '2px 2px 6px rgba(0, 0, 0, 0.8)',
  textAlign: 'center',
  marginTop: theme.spacing(1),
}))

export const DescriptionTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto, sans-serif',
  color: '#cfcfcf',
  textAlign: 'center',
  fontStyle: 'italic',
  margin: theme.spacing(0.5, 0),
}))

export const StartButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#32cd32',
  color: '#fff',
  fontWeight: 'bold',
  textTransform: 'none',
  marginTop: theme.spacing(1),
  boxShadow: '0 0 8px rgba(0, 255, 0, 0.5)',
  '&:hover': {
    backgroundColor: '#28a745',
    boxShadow: '0 0 12px rgba(0, 255, 0, 0.8)',
  },
}))

export const Progress = styled(LinearProgress)(({ theme }) => ({
  height: '5px',
  borderRadius: '5px',
  backgroundColor: theme.palette.grey[700],
  '& .MuiLinearProgress-bar': {
    background: 'linear-gradient(to right, #00ffcc, #9932cc)',
  },
}))

export const LevelStepper = styled(Stepper)(({ theme }) => ({
  padding: theme.spacing(1, 0),
}))
