import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { MessageCardDialogProps } from './types'
import blueSkullGif from '../../assets/gif/skull3.gif'
import redSkullGif from '../../assets/gif/skull7.gif'
import React, { useEffect, useState } from 'react'
import { dialogStyles } from './styles'
import CustomSlideTransition from './CustomSlideTransition'

const MessageCardDialog = ({
  open,
  onClose,
  type = 'info',
  title,
  message,
  buttonText,
}: MessageCardDialogProps) => {
  const [isGifLoaded, setIsGifLoaded] = useState(false) // Track if the GIF is loaded

  const styles = type === 'info' ? dialogStyles.info : dialogStyles.error
  const gifUrl = type === 'info' ? blueSkullGif : redSkullGif

  // Preload the GIF when the component mounts or type changes
  useEffect(() => {
    if (open) {
      const gifImage = new Image()
      gifImage.src = gifUrl
      gifImage.onload = () => setIsGifLoaded(true) // Set loaded when GIF is ready
    } else {
      setIsGifLoaded(false) // Reset the state when dialog is closed
    }
  }, [gifUrl, open])

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={CustomSlideTransition}
        PaperProps={{ sx: styles.dialog }}
      >
        <DialogTitle>
          <Typography sx={styles.title}>{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <Box textAlign='center'>
            {isGifLoaded ? (
              <img src={gifUrl} alt='Animated Logo' style={styles.logo} />
            ) : (
              <CircularProgress
                sx={{
                  color: '#00FFFF',
                }}
              />
            )}
            <Typography sx={styles.content}>{message}</Typography>
            <Box textAlign='center'>
              <Button onClick={onClose} sx={styles.button}>
                {buttonText}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default React.memo(MessageCardDialog)
