import React, { useState } from 'react'
import { Box, Card, Typography, Button, InputBase } from '@mui/material'

interface TextInputCardProps {
  question: string
  length: number
  onSubmit: (text: string) => void // Submit handler
}

const TextInputCard: React.FC<TextInputCardProps> = ({
  question,
  length,
  onSubmit,
}) => {
  const [text, setText] = useState<string>('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    // Limit the input to the specified length
    if (value.length <= length) {
      setText(value)
    }
  }

  const handleSubmit = () => {
    onSubmit(text) // Call the onSubmit handler when clicked
  }

  return (
    <Card
      sx={{
        backgroundColor: '#2E2E2E', // Dark gray for versatility
        padding: '60px 40px', // Increased padding for larger screens
        borderRadius: '16px',
        maxWidth: '800px', // Increased maxWidth for better laptop view
        margin: '20px auto',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)', // Subtle shadow for depth
        textAlign: 'center',
        border: '2px solid rgba(255, 255, 255, 0.2)', // Light border
      }}
    >
      <Typography
        variant='h5'
        sx={{
          fontWeight: 'bold',
          color: '#00ffc3', // Bright cyan for emphasis
          marginBottom: '24px',
          fontFamily: "'Playfair Display', serif", // Unique font family
        }}
      >
        {question}
      </Typography>

      <Box
        sx={{
          width: '100%',
          height: '60px',
          backgroundColor: '#444', // Darker gray for inputs
          border: '2px solid rgba(255, 255, 255, 0.4)', // Lighter border for contrast
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '30px',
          transition: '0.3s',
          '&:hover': {
            backgroundColor: '#555', // Slightly lighter on hover
          },
        }}
      >
        <InputBase
          value={text}
          onChange={handleChange}
          inputProps={{
            maxLength: length,
            style: {
              textAlign: 'center',
              fontSize: '24px',
              color: '#00ffc3', // Bright color for the text
              fontFamily: "'Roboto Mono', monospace",
              outline: 'none',
              width: '100%', // Fill the input area
            },
          }}
          sx={{
            width: '100%',
            height: '100%',
            '& input': {
              textAlign: 'center',
              padding: '0',
              border: 'none',
              background: 'transparent',
            },
          }}
        />
      </Box>

      {/* Submit Button */}
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <Button
          variant='contained'
          onClick={handleSubmit}
          sx={{
            padding: '10px 24px',
            backgroundColor: '#00ffc3',
            color: '#111',
            fontWeight: 'bold',
            borderRadius: '50px',
            textTransform: 'none',
            fontFamily: "'Roboto Mono', monospace",
            fontSize: { xs: '16px', md: '20px' }, // Responsive font size
            boxShadow: '0 0 5px rgba(0, 255, 255, 0.3)',
            '&:hover': {
              backgroundColor: '#00e6b3',
              boxShadow: '0 0 10px rgba(0, 255, 255, 0.5)', // Subtle hover glow
            },
          }}
        >
          Submit
        </Button>
      </Box>
    </Card>
  )
}

export default TextInputCard
