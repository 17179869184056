import { CssBaseline, ThemeProvider } from '@mui/material'
import theme from './styles/theme'
import store from './store'
import { Provider } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import AppRoot from './AppRoot'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <AppRoot />
      </Provider>
    </ThemeProvider>
  )
}

export default App
