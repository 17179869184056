import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import BackgroundImages from '../../components/BackgroundImages'

const UnprotectedLayout = () => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark background for a mysterious effect
        }}
      >
        <BackgroundImages />
        <Outlet />
      </Box>
    </>
  )
}

export default UnprotectedLayout
