import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import SpeedDialComponent from './SpeedDialComponent' // Import the SpeedDialComponent
import BackgroundImages from '../../components/BackgroundImages'

const MainLayout = () => {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark background for a mysterious effect
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <BackgroundImages />

      <Box
        sx={{
          position: 'relative',
          zIndex: 2, // Make sure content appears above the background and overlay
          color: '#FFF', // Text color
        }}
      >
        <Outlet />
      </Box>

      {/* Include the SpeedDial component */}
      <SpeedDialComponent />
    </Box>
  )
}

export default MainLayout
