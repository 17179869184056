import UnProtectedRoute from './UnProtectedRoute'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import { routeUrls } from '../constants/routeUrls'
import WelcomePage from '../pages/WelcomePage'
import Login from '../pages/Login'
import Signup from '../pages/Signup'
import Home from '../pages/Home'
import MainLayout from '../layout/MainLayout'
import Help from '../pages/Help'
import ContactUs from '../pages/ContactUs'
import Profile from '../pages/Profile'
import Level from '../pages/Level'
import UnprotectedLayout from '../layout/UnprotectedLayout'
import LevelLayout from '../layout/LevelLayout'
import Leaderboard from '../pages/Leaderboard'
import PageNotFound from '../pages/PageNotFound'

const Routes = () => {
  const unprotectedRoutes = [
    {
      path: '/',
      element: <UnProtectedRoute />,
      children: [
        {
          path: '/',
          element: <UnprotectedLayout />,
          children: [
            {
              path: routeUrls.WELCOME_PAGE,
              element: <WelcomePage />,
            },
            {
              path: routeUrls.LOGIN,
              element: <Login />,
            },
            {
              path: routeUrls.SIGNUP,
              element: <Signup />,
            },
          ],
        },
      ],
    },
  ]

  const pageNotFound = [
    {
      path: '*',
      element: <PageNotFound />,
    },
  ]

  const protectedRoutes = [
    {
      path: '/',
      element: <ProtectedRoute />,
      children: [
        {
          path: '/',
          element: <MainLayout />,
          children: [
            {
              path: routeUrls.HOME,
              element: <Home />,
            },
            {
              path: routeUrls.HELP,
              element: <Help />,
            },
            {
              path: routeUrls.CONTACTUS,
              element: <ContactUs />,
            },
            {
              path: routeUrls.PROFILE,
              element: <Profile />,
            },
            {
              path: routeUrls.LEADERBOARD + '/:mysteryId',
              element: <Leaderboard />,
            },
          ],
        },
        {
          path: `${routeUrls.LEVEL}/:mysteryId/:level`, // Use template literal for dynamic path
          element: <LevelLayout />,
          children: [
            {
              index: true,
              element: <Level />, // Corrected the typo from "elememnt" to "element"
            },
          ],
        },
      ],
    },
  ]

  const router = createBrowserRouter([
    ...unprotectedRoutes,
    ...protectedRoutes,
    ...pageNotFound,
  ])

  return <RouterProvider router={router} />
}

export default Routes
