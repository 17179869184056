import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { authlogin, authSignup } from './authActions'
import { localstoragekeys } from '../../../constants/constants'

export type TAuthState = {
  fullName: string
  email: string
  isAuthenticated: boolean
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null
  userId: string
}

const initialState: TAuthState = {
  fullName: localStorage.getItem(localstoragekeys.fullName) || '',
  isAuthenticated:
    (!!localStorage.getItem(localstoragekeys.fullName) &&
      !!localStorage.getItem(localstoragekeys.email)) ||
    false,
  loading: 'idle',
  email: localStorage.getItem(localstoragekeys.email) || '',
  userId: localStorage.getItem(localstoragekeys.userId) || '',
  error: null,
}

export const authSlice = createSlice({
  name: 'authSlice',
  initialState: initialState,
  reducers: {
    authlogout: (state) => {
      state.isAuthenticated = false
      state.fullName = ''
      state.error = null
      state.userId = ''
      state.loading = 'idle'

      localStorage.clear()
    },
    resetState: (state) => {
      state.error = null
      state.loading = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      // login cases
      .addCase(authlogin.pending, (state, action) => {
        state.loading = 'pending'
        state.fullName = ''
        state.email = ''
        state.userId = ''
        state.isAuthenticated = false
        state.error = ''

        localStorage.clear()
      })
      .addCase(authlogin.fulfilled, (state, action) => {
        state.loading = 'succeeded'
        state.fullName = action.payload.full_name
        state.email = action.payload.email
        state.userId = action.payload.user_id
        state.isAuthenticated = true
        state.error = ''

        localStorage.setItem(localstoragekeys.fullName, state.fullName)
        localStorage.setItem(localstoragekeys.email, state.email)
        localStorage.setItem(localstoragekeys.userId, state.userId)
      })
      .addCase(authlogin.rejected, (state, action: any) => {
        state.loading = 'failed'
        state.fullName = ''
        state.email = ''
        state.userId = ''
        state.isAuthenticated = false
        state.error = action?.payload || ''

        localStorage.clear()
      })

      // signup cases
      .addCase(authSignup.pending, (state, action) => {
        state.loading = 'pending'
        state.fullName = ''
        state.email = ''
        state.userId = ''
        state.isAuthenticated = false
        state.error = ''

        localStorage.clear()
      })
      .addCase(authSignup.fulfilled, (state, action: any) => {
        state.loading = 'succeeded'
        state.fullName = action.payload.fullName
        state.email = action.payload.email
        state.userId = action.payload.user_id
        state.isAuthenticated = true
        state.error = ''

        localStorage.setItem(localstoragekeys.fullName, state.fullName)
        localStorage.setItem(localstoragekeys.email, state.email)
        localStorage.setItem(localstoragekeys.userId, state.userId)
      })
      .addCase(authSignup.rejected, (state, action: any) => {
        state.loading = 'failed'
        state.fullName = ''
        state.email = ''
        state.userId = ''
        state.isAuthenticated = false
        state.error = action?.payload || ''

        localStorage.clear()
      })
  },
})

export const { authlogout, resetState } = authSlice.actions

export default authSlice.reducer
