import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants/apiendpoints'
import { setModalsData } from '../commonSlice'

export const authlogin = createAsyncThunk(
  'auth/login',
  async (formData: FormData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.LOGIN}`,
        formData,
      )

      dispatch(
        setModalsData({
          messageModal: {
            open: true,
            type: 'info',
            title: 'Welcome Back, Adventurer!',
            message: `Greetings, ${response.data['full_name']}! The secrets of the realm await your discovery. Let's continue the journey!`,
            buttonText: 'Continue the Journey',
          },
        }),
      )

      return {
        ...response.data,
        email: formData.get('email'),
      }
    } catch (error: any) {
      // Return a rejected action with the error message
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message || 'Login Failed')
      }
    }
  },
)

export const authSignup = createAsyncThunk(
  'auth/signup',
  async (formData: FormData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.SIGNUP}`,
        formData,
      )
      dispatch(
        setModalsData({
          messageModal: {
            open: true,
            type: 'info',
            title: 'Mysteries Await!',
            message: `Welcome, ${formData.get('full_name')}! You've unlocked the door to a world of secrets and adventure. Let the journey begin!`,
            buttonText: 'Continue the Journey',
          },
        }),
      )
      return {
        ...response.data,
        fullName: formData.get('full_name'),
        email: formData.get('email'),
      }
    } catch (error: any) {
      // Return a rejected action with the error message
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(
          error.message ||
            'An unexpected glitch in the magic has blocked your registration. Please try again in a moment.',
        )
      }
    }
  },
)
