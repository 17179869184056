import { LoadingButton } from '@mui/lab'
import { Box, Button, TextField, Typography } from '@mui/material'
import { styled, Theme } from '@mui/material/styles'

// Styled Components
export const Header = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(2),
  },
}))

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '2.2rem',
  fontWeight: 'bold',
  color: '#FFD700',
  textShadow: '0 0 8px rgba(255, 215, 0, 0.8)',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.8rem',
  },
}))

export const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: '#D1D1D1',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
  },
}))

export const ContactForm = styled(Box)(({ theme }) => ({
  backgroundColor: '#2E3445',
  padding: theme.spacing(3),
  borderRadius: '10px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  width: '100%',
  maxWidth: '600px',
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}))

export const inputStyles = {
  '& .MuiInputBase-root': {
    borderRadius: '10px',
    backgroundColor: '#1C2331',
    color: '#D1D1D1',
  },
  '& label.Mui-focused': {
    color: '#FFD700',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8FF1FF',
    },
    '&:hover fieldset': {
      borderColor: '#FFD700',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFD700',
    },
  },
}

export const FormField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiInputBase-root': {
    borderRadius: '10px',
    backgroundColor: '#1C2331',
    color: '#D1D1D1',
  },
  '& label.Mui-focused': {
    color: '#FFD700',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8FF1FF',
    },
    '&:hover fieldset': {
      borderColor: '#FFD700',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFD700',
    },
  },
}))

export const SubmitButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: '#8FF1FF',
  color: '#1C2331',
  padding: theme.spacing(1, 4),
  borderRadius: '20px',
  fontWeight: 'bold',
  marginTop: theme.spacing(2),
  '&:hover': {
    backgroundColor: '#6EB9C0',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1, 2),
  },
}))

export const Footer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  textAlign: 'center',
  color: '#D1D1D1',
  fontSize: '0.8rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.7rem',
  },
}))
