import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from './store'
import { resetMessageModal, TModalState } from './store/slice/commonSlice'
import { Box } from '@mui/material'
import Routes from './routes/routes'
import MessageCardDialog from './components/MessageCardDialog'
import { ToastContainer } from 'react-toastify'
import Swal from 'sweetalert2'

const AppRoot = () => {
  const dispatch = useDispatch<AppDispatch>()

  const modalsState: TModalState = useSelector(
    (state: RootState) => state.commonReducer.modals,
  )

  const handleCloseMessageDialogBox = () => {
    dispatch(resetMessageModal())
  }

  return (
    <>
      <Box>
        <Routes />
        <ToastContainer />
        {modalsState?.messageModal?.open ? (
          <MessageCardDialog
            open={modalsState?.messageModal?.open} // Control visibility
            onClose={handleCloseMessageDialogBox} // Function to close the dialog
            type={modalsState?.messageModal.type} // Type can be 'info', 'error', or 'warning'
            title={modalsState?.messageModal?.title} // Dialog title
            message={modalsState?.messageModal?.message} // Message content
            buttonText={modalsState?.messageModal?.buttonText}
          />
        ) : (
          ''
        )}
      </Box>
    </>
  )
}

export default AppRoot
