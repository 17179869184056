export const routeUrls = {
  WELCOME_PAGE: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',

  HOME: '/home',
  PROFILE: '/profile',
  MYSTERIES: '/mysteries',
  LEVEL: '/level',
  LEADERBOARD: '/leaderboard',
  HELP: '/help',
  CONTACTUS: '/contactus',
}
