import React from 'react'
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material/styles'

// Styled Components
const Header = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(4),
}))

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  color: '#FFD700',
  textShadow: '0 0 8px rgba(255, 215, 0, 0.8)',
}))

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  color: '#D1D1D1',
}))

const SearchBar = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  width: '100%',
}))

const SearchInput = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: '#2E3445',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8FF1FF',
  },
}))

const FAQSection = styled(Accordion)(({ theme }) => ({
  backgroundColor: '#1C2331',
  color: '#D1D1D1',
  marginBottom: theme.spacing(1),
}))

const FAQSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: '#2E3445',
  color: '#D1D1D1',
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
  },
}))

const FAQDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: '#1C2331',
  color: '#D1D1D1',
}))

const ContactButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  backgroundColor: '#8FF1FF',
  color: '#1C2331',
  '&:hover': {
    backgroundColor: '#6EB9C0',
  },
}))

const Footer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  textAlign: 'center',
  color: '#D1D1D1',
}))

// Main Component
const Help: React.FC = () => {
  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: '#1C2331',
        color: '#D1D1D1',
        minHeight: '100vh',
      }}
    >
      {/* Header Section */}
      <Header>
        <Title>Your Adventure Guide</Title>
        <Subtitle>
          Stuck on a riddle? Confused about the game? We're here to guide you
          through your adventure!
        </Subtitle>
      </Header>

      {/* Main Content */}
      <Box>
        {/* FAQ Categories */}
        <FAQSection>
          <FAQSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Getting Started</Typography>
          </FAQSummary>
          <FAQDetails>
            <Typography>
              You can start by creating an account, choosing a mystery to solve,
              and following the basic instructions provided on the platform. The
              game interface is designed to guide you step by step through the
              adventure
            </Typography>
          </FAQDetails>
        </FAQSection>

        <FAQSection>
          <FAQSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>How to Solve Riddles</Typography>
          </FAQSummary>
          <FAQDetails>
            <Typography>
              Start by breaking down the riddle into parts. Look for clues in
              the wording, imagery, and hidden patterns. If you get stuck, take
              a step back and try approaching the puzzle from a different angle.
              You can also use hints if available but beware of penalties for
              using too many hints.
            </Typography>
          </FAQDetails>
        </FAQSection>

        <FAQSection>
          <FAQSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Progress & Leaderboard</Typography>
          </FAQSummary>
          <FAQDetails>
            <Typography>
              The leaderboard displays the top players based on the number of
              riddles they’ve solved, points earned, and completion time. Your
              progress is automatically tracked as you solve riddles and level
              up, allowing you to unlock new content and challenges.
            </Typography>
          </FAQDetails>
        </FAQSection>

        <FAQSection>
          <FAQSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Account & Settings</Typography>
          </FAQSummary>
          <FAQDetails>
            <Typography>
              You can manage your account by going to the profile section, where
              you can change your username, password, or email. Additionally,
              you can modify game settings such as notifications and difficulty
              level from the settings menu.
            </Typography>
          </FAQDetails>
        </FAQSection>

        <FAQSection>
          <FAQSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Technical Issues</Typography>
          </FAQSummary>
          <FAQDetails>
            <Typography>
              If you experience any technical difficulties, first try refreshing
              the page or clearing your browser’s cache. If the problem
              persists, you can report bugs through the support section or
              contact our technical team for assistance.
            </Typography>
          </FAQDetails>
        </FAQSection>

        <FAQSection>
          <FAQSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Advanced Puzzle Solving Techniques</Typography>
          </FAQSummary>
          <FAQDetails>
            <Typography>
              For advanced puzzles, try using techniques like pattern
              recognition, reverse logic, and hunting for hidden clues.
              Sometimes the answer might be in the smallest detail, so pay close
              attention to everything. Collaborating with friends or fellow
              players can also provide new perspectives.
            </Typography>
          </FAQDetails>
        </FAQSection>
      </Box>
    </Box>
  )
}

export default Help
