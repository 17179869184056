// Button styles for Login Page
export const primaryButtonStyle = {
  background: 'linear-gradient(45deg, #7B1FA2, #E040FB)',
  fontSize: { xs: '1rem', sm: '1.2rem' },
  padding: { xs: '4px 16px', sm: '6px 20px' }, // Reduced the vertical padding
  borderRadius: '25px',
  '&:hover': {
    background: 'linear-gradient(45deg, #E040FB, #7B1FA2)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
  },
}

export const secondaryButtonStyle = {
  borderColor: 'transparent', // Making border color transparent for a cleaner gradient
  background: 'linear-gradient(45deg, #E040FB, #7B1FA2)',
  color: 'white',
  fontSize: { xs: '0.8rem', sm: '0.9rem' }, // Decrease font size
  padding: { xs: '6px 16px', sm: '8px 20px' }, // Reduced the vertical padding
  borderRadius: '25px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)', // Adding shadow for a polished look
  backgroundClip: 'padding-box',
  '&:hover': {
    background: 'linear-gradient(45deg, #7B1FA2, #E040FB)',
    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)', // Slightly bigger shadow on hover
  },
}

// Styles for input fields
export const inputStyles = {
  borderRadius: '12px',
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  color: '#E0E0E0',
  '& input': {
    color: '#E0E0E0',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none', // Remove the border
    },
    '&:hover fieldset': {
      border: 'none', // Ensure no border on hover
    },
    '&.Mui-focused fieldset': {
      border: 'none', // Ensure no border when focused
    },
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#FFD700',
    boxShadow: '0 0 10px rgba(255, 215, 0, 0.8)',
  },
}

// Styles for form container
export const formContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: { xs: '15px', sm: '25px' },
  borderRadius: '20px',
  background:
    'linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.6))',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  boxShadow: '0 12px 30px rgba(0, 0, 0, 0.7)',
  textAlign: 'center',
  maxWidth: '500px',
  width: '90%',
  minHeight: '250px',
  backdropFilter: 'blur(10px)',
  zIndex: 10,
  overflow: 'hidden',
  transformOrigin: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0.5,
    mixBlendMode: 'multiply',
  },
}
