import React, { useState, useEffect } from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  LinearProgress,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Stack,
  Slide,
  Fade,
  Skeleton,
  styled,
} from '@mui/material'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LockIcon from '@mui/icons-material/Lock'
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite'
import ReplayIcon from '@mui/icons-material/Replay'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import { MysteryQuizCardProps } from './types'
import {
  CardContentStyled,
  CardMediaStyled,
  DescriptionTypography,
  LeaderboardButton,
  LevelStepper,
  Overlay,
  Progress,
  StartButton,
  StyledCard,
  TitleTypography,
} from './styles'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from '../../constants/routeUrls'

const MysteryQuizCard: React.FC<MysteryQuizCardProps> = ({
  title,
  description,
  image,
  levels,
  completedLevels,
  currentLevel,
  onStartClick,
  onLeaderboardClick,
  loading,
}) => {
  const navigate = useNavigate()

  const progressPercentage = (completedLevels / levels.length) * 100

  const [slideIn, setSlideIn] = useState(false)
  const [fadeIn, setFadeIn] = useState(false)

  // Find the title of the current level
  const currentLevelData = levels.find(
    (level: any) => level.level_number === currentLevel,
  )
  const currentLevelTitle = currentLevelData?.level_name || 'Unknown Level'
  const isLevelCompleted = completedLevels >= levels?.length // Check if the user has completed all levels
  const isLevelActive = currentLevelData?.is_active // Check if the current level is active

  useEffect(() => {
    setTimeout(() => {
      setSlideIn(true)
      setFadeIn(true)
    }, 300)
  }, [])

  return (
    <Fade in={fadeIn} timeout={1000}>
      <Slide in={slideIn} direction='down' timeout={800}>
        <StyledCard>
          {loading ? (
            <Skeleton sx={{ bgcolor: 'grey.800', mx: 2 }} height={'160px'} />
          ) : (
            <CardMediaStyled image={image}>
              <Overlay />
            </CardMediaStyled>
          )}

          <CardContentStyled>
            <TitleTypography variant='h6'>
              {loading ? <Skeleton sx={{ bgcolor: 'grey.800' }} /> : title}
            </TitleTypography>

            <DescriptionTypography variant='body2'>
              {loading ? (
                <Skeleton sx={{ bgcolor: 'grey.800' }} />
              ) : (
                description
              )}
            </DescriptionTypography>

            <Box mt={1}>
              <Typography variant='subtitle2' align='center'>
                {loading ? (
                  <Skeleton sx={{ bgcolor: 'grey.800' }} />
                ) : (
                  <Typography>
                    You've completed {completedLevels} out of {levels.length}{' '}
                    levels
                  </Typography>
                )}
              </Typography>
              <Box mt={0.5}>
                {loading ? (
                  <Skeleton sx={{ bgcolor: 'grey.800' }} height={50} />
                ) : (
                  <Progress variant='determinate' value={progressPercentage} />
                )}
              </Box>
            </Box>

            {loading ? (
              <Skeleton sx={{ bgcolor: 'grey.800' }} />
            ) : (
              <LevelStepper activeStep={completedLevels} alternativeLabel>
                {levels.map((level: any, index: number) => {
                  const levelNumber = level.level_number
                  const isLevelCompleted = levelNumber <= completedLevels
                  const isLevelAvailable = level?.is_active && !isLevelCompleted
                  const isLocked = level?.is_active

                  return (
                    <Step key={levelNumber} completed={isLevelCompleted}>
                      <StepLabel
                        icon={
                          isLevelCompleted ? (
                            <CheckCircleIcon style={{ color: '#32cd32' }} />
                          ) : isLevelAvailable ? (
                            <PlayCircleFilledWhiteIcon
                              style={{ color: '#f0e68c' }}
                            />
                          ) : isLocked ? (
                            <LockIcon style={{ color: '#555555' }} />
                          ) : (
                            <HourglassEmptyIcon style={{ color: '#cfcfcf' }} />
                          )
                        }
                        sx={{
                          cursor: isLevelCompleted ? 'pointer' : 'default',
                          '& .MuiStepLabel-label': {
                            color: '#cfcfcf !important',
                          },
                        }}
                      >
                        <Stack alignItems={'center'}>
                          {`Level ${levelNumber}`}
                        </Stack>
                      </StepLabel>
                    </Step>
                  )
                })}
              </LevelStepper>
            )}

            <Divider sx={{ backgroundColor: '#444', marginY: 1.5 }} />

            <Typography variant='subtitle2' align='center'>
              {loading ? (
                <Skeleton sx={{ bgcolor: 'grey.800' }} />
              ) : (
                <>Recent Activity</>
              )}
            </Typography>

            <DescriptionTypography variant='body2'>
              {loading ? (
                <Skeleton sx={{ bgcolor: 'grey.800' }} />
              ) : (
                <Typography>
                  You're currently at Level {currentLevel} of "
                  {currentLevelTitle}"
                </Typography>
              )}
            </DescriptionTypography>

            <Box mt={1}>
              {loading ? (
                <Skeleton sx={{ bgcolor: 'grey.800' }} />
              ) : (
                <StartButton
                  fullWidth
                  size='small'
                  onClick={onStartClick}
                  disabled={!isLevelActive} // Disable button if the level is not active
                >
                  {isLevelCompleted ? (
                    'Mystery Passed' // Display when the mystery is passed
                  ) : isLevelActive ? (
                    `Continue Level ${currentLevel}` // Display if level is active
                  ) : (
                    <>
                      <LockIcon style={{ marginRight: 4 }} /> {/* Lock icon */}
                      Level {currentLevel}
                    </>
                  )}
                </StartButton>
              )}
            </Box>

            {/* New Leaderboard Button */}
            <LeaderboardButton
              onClick={onLeaderboardClick}
              sx={{ mt: 1 }}
              fullWidth
              size='small'
            >
              View Leaderboard
            </LeaderboardButton>
          </CardContentStyled>
        </StyledCard>
      </Slide>
    </Fade>
  )
}

export default MysteryQuizCard
