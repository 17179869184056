import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS } from '../../constants/apiendpoints'
import { API_METHODS } from '../../constants/constants'
import { MysteryApiTags } from './tags'
import { MysteryPayload, MysteryResponse } from './types'

export const mysterApi = createApi({
  reducerPath: 'mysteryApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    MysteryApiTags.MYSTERY_CREATED,
    MysteryApiTags.MYSTERY_DELETED,
    MysteryApiTags.MYSTERY_MODIFIED,
    MysteryApiTags.MYSTERY_SINGLE,
  ],
  endpoints: (builder) => ({
    // get all mystery
    getAllMystery: builder.query<MysteryResponse, MysteryPayload>({
      query: (params: MysteryPayload) => ({
        url: API_ENDPOINTS.GET_ALL_MYSTERY, // The ID is part of the URL
        method: API_METHODS.GET,
        params: {
          user_id: params.userId,
        },
      }),
      providesTags: [
        MysteryApiTags.MYSTERY_CREATED,
        MysteryApiTags.MYSTERY_DELETED,
        MysteryApiTags.MYSTERY_MODIFIED,
      ],
      transformResponse: (response: MysteryResponse) => {
        return response
      },
    }),
  }),
})

export const { useGetAllMysteryQuery } = mysterApi
