import * as yup from 'yup'
import { EMAILREGEX } from '../../constants/constants'

export const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .matches(
      EMAILREGEX,
      'The email you’ve entered looks mysterious. Please use a valid email address.',
    ) // Mystical email validation
    .required(
      'We need your email to send you important details about your adventure. Please provide it.',
    ), // Required email field
  password: yup
    .string()
    .required('Every hero needs a magical password. Please create one.'), // Mystical password prompt
})
