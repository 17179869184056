import { useForm, SubmitHandler } from 'react-hook-form'
import { ContactUsForm } from './types'
import { contactUsValidationSchema } from './validations'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import { useSendMessageMutation } from '../../api/contactusApi'
import { ContactUsResponse } from '../../api/contactusApi/types'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../utils/toastMessage'

const useContactUsModal = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm<ContactUsForm>({
    defaultValues: {
      subject: '',
      message: '',
    },
    resolver: yupResolver(contactUsValidationSchema),
  })

  const { userId } = useSelector((state: RootState) => state.authReducer)

  const [sendMessage, { isLoading }] = useSendMessageMutation()

  useEffect(() => {
    if (errors) {
      // Extract the first error message
      const firstErrorMessage = Object.values(errors)[0]?.message || ''

      if (firstErrorMessage) {
        toastErrorMessage(firstErrorMessage)
      }
    }
  }, [errors])

  const onSubmit: SubmitHandler<ContactUsForm> = async (data) => {
    const formData = new FormData()

    formData.append('user_id', userId.toString())
    formData.append('subject', data.subject)
    formData.append('message', data.message)

    sendMessage(formData)
      .unwrap()
      .then((data: ContactUsResponse) => {
        if (data?.success) {
          toastSuccessMessage(
            '🎉 Huzzah! Your message has been sent successfully into the ether! 🌟 Expect a swift response from our team of wizards!',
          )
          reset()
        } else {
          toastErrorMessage(
            data?.message ||
              '🚫 Oh no! It seems there was a hiccup in the magic. We were unable to send your message.',
          )
        }
      })
      .catch((error) => {
        toastErrorMessage(
          error?.data?.message ||
            '🚫 Oops! The mystical forces have blocked your message. Try again, brave traveler!',
        )
      })
  }

  return {
    control,
    register,
    errors,
    reset,
    onSubmit,
    handleSubmit,
    watch,
    loading: isLoading,
  }
}

export default useContactUsModal
