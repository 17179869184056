import ReplyIcon from '@mui/icons-material/Reply'
import { IconButton, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from '../../constants/routeUrls'

const BackButton = () => {
  const navigate = useNavigate()
  const theme = useTheme()

  // Check if screen size is less than or equal to 'md' breakpoint
  const isMdOrLess = useMediaQuery(theme.breakpoints.down('md'))

  const handleBackSubmit = () => {
    navigate(routeUrls.HOME)
  }

  return (
    <IconButton
      color='primary'
      aria-label='back button'
      sx={{
        position: 'fixed',
        top: 20,
        left: 20,
        zIndex: 1000, // Ensure it's above the content
        background:
          'linear-gradient(135deg, rgba(255,255,255,0.3), rgba(255,255,255,0.1))', // Glass effect background
        color: '#fff', // White icon color for better visibility
        padding: '12px', // Adjust padding to make it more spacious
        borderRadius: '50%', // Circular shape
        backdropFilter: 'blur(10px)', // Frosted glass effect
        border: '1px solid rgba(255, 255, 255, 0.3)', // Border to enhance glass effect
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', // Soft shadow for depth
        '&:hover': {
          background:
            'linear-gradient(135deg, rgba(255,255,255,0.5), rgba(255,255,255,0.2))', // Slightly brighter hover background
          transform: 'scale(1.1)', // Scale up on hover
          boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.3)', // Enhanced shadow on hover
        },
      }}
      onClick={handleBackSubmit}
    >
      <ReplyIcon fontSize={isMdOrLess ? 'medium' : 'large'} />
    </IconButton>
  )
}

export default BackButton
