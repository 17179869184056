import { Backdrop, Box, CircularProgress } from '@mui/material'
import Title from './Title'
import Grid from '@mui/material/Grid2'
import ComingSoonCard from '../../components/ComingSoonCard'
import MysteryQuizCard from '../../components/MysteryQuizCard'
import { useGetAllMysteryQuery } from '../../api/mysteryApi'
import useHomeHook from './useHomeHook'
import CustomLoader from '../../components/CustomLoader'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from '../../constants/routeUrls'

const Home = () => {
  const { userId } = useSelector((state: RootState) => state.authReducer)

  const {
    data: mysteryListData,
    isLoading: isMysteryListLoading,
    isSuccess: isMysteryListSuccess,
  } = useGetAllMysteryQuery({ userId })

  const navigate = useNavigate()

  const { handleSubmit } = useHomeHook()

  // Leaderboard Navigate

  const handleLeaderboardNavigate = (mysteryId: string | number) => {
    navigate(routeUrls.LEADERBOARD + '/' + mysteryId)
  }
  return (
    <>
      <Box mb={2} p={1}>
        {/* Title  */}

        <Backdrop
          sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 2 })}
          open={isMysteryListLoading || !isMysteryListSuccess}
        >
          <CustomLoader />
        </Backdrop>

        <Title title='The Riddle Escape' />

        <Grid
          container
          rowSpacing={5}
          columnSpacing={10}
          justifyContent={'center'}
        >
          {mysteryListData?.results?.available_mysteries?.map((data, index) => {
            // Calculate completed levels and current level
            const completedLevels =
              data?.levels?.filter((level) => level.passed === 1).length || 0
            const currentLevel =
              data?.levels?.findIndex((level) => level.passed !== 1) + 1 || 1

            return (
              <Grid key={index}>
                <MysteryQuizCard
                  loading={isMysteryListLoading || !isMysteryListSuccess}
                  title={data?.title}
                  description={data?.description}
                  image={data?.photo_url}
                  levels={data?.levels}
                  completedLevels={completedLevels} // Use the calculated completed levels
                  currentLevel={currentLevel} // Use the calculated current level
                  onStartClick={() => {
                    handleSubmit(data?.mystery_id, currentLevel) // Use the calculated current level
                  }}
                  onLeaderboardClick={() => {
                    handleLeaderboardNavigate(data?.mystery_id)
                  }}
                />
              </Grid>
            )
          })}

          <Grid>
            {mysteryListData?.results?.coming_soon_mysteries?.map(
              (data, index) => (
                <Grid key={index}>
                  <ComingSoonCard
                    loading={isMysteryListLoading || !isMysteryListSuccess}
                    image={data?.photo_url}
                    title={data?.title}
                    description={data?.description}
                  />
                </Grid>
              ),
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Home
