import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS } from '../../constants/apiendpoints'
import { API_METHODS } from '../../constants/constants'
import { LevelApiTags } from './tags'
import {
  LevelPayload,
  LevelResponse,
  LevelsPayload,
  LevelsResponse,
} from './types'

export const levelApi = createApi({
  reducerPath: 'levelApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    LevelApiTags.LEVEL_CREATED,
    LevelApiTags.LEVEL_DELETED,
    LevelApiTags.LEVEL_MODIFIED,
    LevelApiTags.LEVEL_SINGLE,
  ],
  endpoints: (builder) => ({
    getAllLevelsByMysteryId: builder.query<LevelsResponse, LevelsPayload>({
      query: (params: LevelPayload) => ({
        url: API_ENDPOINTS.GET_ALL_LEVELS_BY_MYSTERY_ID, // The ID is part of the URL
        method: API_METHODS.GET,
        params: params,
      }),
      providesTags: [
        LevelApiTags.LEVEL_CREATED,
        LevelApiTags.LEVEL_DELETED,
        LevelApiTags.LEVEL_MODIFIED,
      ],
      transformResponse: (response: LevelsResponse) => {
        return response
      },
    }),

    // get level by mystery id and level number
    getLevelById: builder.query<LevelResponse, LevelPayload>({
      query: (params: LevelPayload) => ({
        url: API_ENDPOINTS.GET_LEVEL_BY_ID, // The ID is part of the URL
        method: API_METHODS.GET,
        params: params,
      }),
      providesTags: [
        LevelApiTags.LEVEL_CREATED,
        LevelApiTags.LEVEL_DELETED,
        LevelApiTags.LEVEL_MODIFIED,
      ],
      transformResponse: (response: LevelResponse) => {
        return response
      },
    }),
  }),
})

export const { useGetLevelByIdQuery, useGetAllLevelsByMysteryIdQuery } =
  levelApi
