import React, { useState, useEffect } from 'react'
import { Card, Typography, Button, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Confetti from 'react-confetti'

const MysteryCompletedCard: React.FC = () => {
  const navigate = useNavigate()

  const handleNavigateHome = () => {
    navigate('/') // Navigate to the home page
  }

  return (
    <Card
      sx={{
        backgroundColor: '#1e1e1e',
        padding: '30px 20px',
        borderRadius: '16px',
        maxWidth: '600px',
        margin: '20px auto',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)',
        textAlign: 'center',
        border: '2px solid rgba(255, 255, 255, 0.2)',
        position: 'relative',
      }}
    >
      <Typography
        variant='h3'
        sx={{
          fontWeight: 'bold',
          color: '#ffcc00',
          marginBottom: '16px',
          fontFamily: "'Bebas Neue', cursive",
          letterSpacing: '2px',
          textTransform: 'uppercase',
        }}
      >
        🎉 Mystery Solved!
      </Typography>

      <Typography
        variant='h5'
        sx={{
          fontWeight: 'medium',
          color: '#00ffc3',
          marginBottom: '24px',
          fontFamily: "'Roboto Slab', serif",
          letterSpacing: '1px',
          textTransform: 'uppercase',
        }}
      >
        You’ve passed the mystery, congratulations!
      </Typography>

      <Button
        variant='contained'
        onClick={handleNavigateHome}
        sx={{
          padding: '12px 30px',
          backgroundColor: '#00ffc3',
          color: '#111',
          fontWeight: 'bold',
          borderRadius: '50px',
          textTransform: 'uppercase',
          fontFamily: "'Bebas Neue', cursive",
          fontSize: '18px',
          letterSpacing: '2px',
          boxShadow: '0 0 10px rgba(0, 255, 255, 0.5)',
          '&:hover': {
            backgroundColor: '#00e6b3',
            boxShadow: '0 0 15px rgba(0, 255, 255, 0.7)',
          },
        }}
      >
        Go Home
      </Button>
    </Card>
  )
}

export default MysteryCompletedCard
