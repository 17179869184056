import { Box, Button, Fade, Slide, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from '../../constants/routeUrls'

const WelcomePage = () => {
  const navigate = useNavigate()

  const navigateToLogin = () => {
    navigate(routeUrls.LOGIN)
  }

  const navigateToSignup = () => {
    navigate(routeUrls.SIGNUP)
  }
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          color: 'white',
          textAlign: 'center',
          padding: { xs: '1rem', sm: '2rem', md: '3rem', lg: '4rem' },
        }}
      >
        <Fade in={true} timeout={3000} unmountOnExit>
          <Typography
            variant='h1'
            sx={{
              position: 'relative',
              display: 'inline-block',
              fontSize: { xs: '2.5rem', sm: '4rem', md: '5rem', lg: '6rem' },
              mb: 2,
              textShadow: '0 0 15px #FFD700',
              fontFamily: '"Cinzel", serif',
              fontWeight: 700,
              letterSpacing: '2px',
              '& span': {
                display: 'inline-block',
                opacity: 0,
                position: 'relative',
              },
            }}
          >
            Riddle Escape
          </Typography>
        </Fade>

        <Fade in={true} timeout={3000} unmountOnExit>
          <Typography
            variant='h4'
            sx={{
              fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
              mb: 3,
              color: '#E0E0E0',
            }}
          >
            Unlock the Secrets of Our Riddles!
          </Typography>
        </Fade>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
            mt: 3,
          }}
        >
          <Slide
            direction='up'
            in={true}
            timeout={2000}
            mountOnEnter
            unmountOnExit
          >
            <Button
              variant='contained'
              onClick={navigateToSignup}
              sx={{
                background: 'linear-gradient(45deg, #7B1FA2, #E040FB)',
                fontSize: { xs: '1rem', sm: '1.2rem' },
                padding: { xs: '8px 16px', sm: '10px 20px' },
                borderRadius: '25px',
                '&:hover': {
                  background: 'linear-gradient(45deg, #E040FB, #7B1FA2)',
                },
              }}
            >
              Join the Adventure
            </Button>
          </Slide>

          <Slide
            direction='up'
            in={true}
            timeout={3000}
            mountOnEnter
            unmountOnExit
          >
            <Button
              variant='outlined'
              onClick={navigateToLogin}
              sx={{
                borderColor: 'transparent', // Making border color transparent for a cleaner gradient
                background: 'linear-gradient(45deg, #E040FB, #7B1FA2)',
                color: 'white',
                fontSize: { xs: '1rem', sm: '1.2rem' },
                padding: { xs: '8px 16px', sm: '10px 20px' },
                borderRadius: '25px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)', // Adding shadow for a polished look
                backgroundClip: 'padding-box',
                '&:hover': {
                  background: 'linear-gradient(45deg, #7B1FA2, #E040FB)',
                  boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)', // Slightly bigger shadow on hover
                },
              }}
            >
              Continue the Journey
            </Button>
          </Slide>
        </Box>
      </Box>
    </>
  )
}

export default WelcomePage
