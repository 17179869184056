import * as yup from 'yup'

export const contactUsValidationSchema = yup.object().shape({
  subject: yup
    .string()
    .required(
      'What is the subject of your query? Your adventure needs a title.',
    ), // Mystical subject prompt

  message: yup
    .string()
    .min(
      10,
      'Your message must be at least 10 characters long to reveal its full potential.',
    )
    .required(
      'Please share your message so we can embark on this journey together.',
    ), // Mystical message prompt
})
