import {
  Box,
  styled,
  Tab,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material'

// Styled Components
export const Header = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(4),
}))

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  color: '#FFD700', // Gold Title
  letterSpacing: '2px',
}))

export const LeaderboardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#1C2331', // Dark Background
  color: '#D1D1D1',
  minHeight: '100vh',
  padding: '20px',
  [theme.breakpoints.down('sm')]: {
    padding: '10px',
  },
}))

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      height: '4px', // Custom scrollbar height
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#FFD700', // Gold scrollbar
    },
  },
}))

export const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '14px',
  color: '#D1D1D1',
  textTransform: 'uppercase',
  padding: theme.spacing(1, 4),
  margin: theme.spacing(0, 1),
  borderRadius: '4px',
  minWidth: '100px',
  '&:hover': {
    color: '#FFFFFF', // White text on hover
  },
  '&.Mui-selected': {
    color: '#FFD700', // Gold for selected tab
    borderBottom: '3px solid #FFD700', // Underline effect for selected tab
  },
}))

export const DisabledTab = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#A0A0A0',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  padding: theme.spacing(1.2, 4),
  borderRadius: '4px',
  backgroundColor: '#292E3E',
  margin: theme.spacing(0, 1),
  cursor: 'not-allowed',
  gap: theme.spacing(1),
  opacity: 0.6,
}))

// Styled TableCell
export const TableCellStyled = styled(TableCell)(({ theme }) => ({
  color: '#D1D1D1',
  borderBottom: '1px solid #2E3445',
}))

// Unique styles for 1st, 2nd, and 3rd place
export const FirstPlaceRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#FFD700', // Gold for first place
  '& td': {
    color: '#000000', // Black text
    fontWeight: 'bold',
  },
  boxShadow: '0px 4px 12px rgba(255, 215, 0, 0.5)', // Subtle shadow effect
}))

export const SecondPlaceRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#C0C0C0', // Silver for second place
  '& td': {
    color: '#000000', // Black text
    fontWeight: 'bold',
  },
  boxShadow: '0px 4px 12px rgba(192, 192, 192, 0.5)', // Subtle shadow effect
}))

export const ThirdPlaceRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#CD7F32', // Bronze for third place
  '& td': {
    color: '#FFFFFF', // White text for better contrast
    fontWeight: 'bold',
  },
  boxShadow: '0px 4px 12px rgba(205, 127, 50, 0.5)', // Subtle shadow effect
}))

// Normal table row style
export const TableRowStyled = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#2A303D',
  },
}))

// Pagination styling
export const PaginationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(3),
  '& .MuiPaginationItem-root': {
    color: '#FFD700', // Gold color for pagination numbers
    fontWeight: 'bold',
  },
  '& .Mui-selected': {
    backgroundColor: '#FFD700 !important', // Gold background for selected page
    color: '#1C2331 !important', // Dark text on selected page
  },
}))

// Custom PNG Icon Component
export interface CustomPngIconProps {
  src: string // Prop for the image source
  alt?: string // Optional prop for the alt text
}
