import 'react-slideshow-image/dist/styles.css'
import { Zoom } from 'react-slideshow-image'

interface BackgroundImageProps {
  image: string // Define the expected type for the image prop
}

const BackgroundImage: React.FC<BackgroundImageProps> = ({ image }) => {
  return (
    <>
      <div
        className='slide-container'
        style={{
          position: 'fixed', // Set position to absolute so it sits behind the content
          top: 0,
          left: 0,
          width: '100%',
          minHeight: '100vh',
          overflow: 'hidden',
          zIndex: -1, // Lower z-index to send it behind the content
        }}
      >
        <img
          style={{
            width: '100%',
            height: '100vh',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            objectFit: 'cover',
          }}
          src={image}
        />
      </div>
    </>
  )
}

export default BackgroundImage
