import swal from 'sweetalert2'
import SuccessGif from '../../../assets/gif/success.gif'
import FailedGif from '../../../assets/gif/failed.gif'

// Function to show success alert for passing the level
export function showSuccessAlert() {
  swal.fire({
    title: 'Level Passed!',
    text: 'You have passsed this level! 🎉',
    imageUrl: SuccessGif,
    imageHeight: '100px',
    imageWidth: '100px',
    confirmButtonText: 'Continue',
    background: '#1A1A1A', // Dark background
    color: '#ffec5c', // Yellow text
    customClass: {
      title: 'alert-title',
      popup: 'alert-popup',
      confirmButton: 'alert-confirm-button',
      image: 'alert-image',
    },
    animation: true,
  })
}

// Function to show error alert for incorrect answers
export function showErrorAlert() {
  swal.fire({
    title: 'Oops!',
    text: 'That answer is incorrect. Try again! ❌',
    confirmButtonText: 'Retry',
    imageUrl: FailedGif,
    imageHeight: '100px',
    imageWidth: '100px',
    background: '#1A1A1A', // Dark background
    color: '#ffec5c', // Yellow text
    customClass: {
      title: 'alert-title',
      popup: 'alert-popup',
      confirmButton: 'alert-confirm-button',
      image: 'alert-image',
    },
    animation: true,
  })
}
