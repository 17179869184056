import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS } from '../../constants/apiendpoints'
import { API_METHODS } from '../../constants/constants'
import { RiddleApiTags } from './tags'
import { RiddleAnswerResponse, RiddlePayload, RiddleResponse } from './types'
import { LevelApiTags } from '../levelApi/tags'
import { levelApi } from '../levelApi'
import { MysteryApiTags } from '../mysteryApi/tags'
import { mysterApi } from '../mysteryApi'

export const riddleApi = createApi({
  reducerPath: 'riddleApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    RiddleApiTags.RIDDLE_CREATED,
    RiddleApiTags.RIDDLE_DELETED,
    RiddleApiTags.RIDDLE_MODIFIED,
    RiddleApiTags.RIDDLE_SINGLE,
    LevelApiTags.LEVEL_CREATED,
    MysteryApiTags.MYSTERY_CREATED,
  ],
  endpoints: (builder) => ({
    // get riddle by mystery id and level number
    getRiddleById: builder.query<RiddleResponse, RiddlePayload>({
      query: (params: RiddlePayload) => ({
        url: API_ENDPOINTS.GET_RIDDLE_BY_ID, // The ID is part of the URL
        method: API_METHODS.GET,
        params: params,
      }),
      providesTags: [
        RiddleApiTags.RIDDLE_CREATED,
        RiddleApiTags.RIDDLE_DELETED,
        RiddleApiTags.RIDDLE_MODIFIED,
      ],
      transformResponse: (response: RiddleResponse) => {
        return response
      },
    }),

    // submit riddle answer
    submitRiddle: builder.mutation<RiddleAnswerResponse, FormData>({
      query: (params: FormData) => ({
        url: API_ENDPOINTS.SEND_RIDDLE_ANSWER,
        method: API_METHODS.POST,
        body: params,
      }),
      invalidatesTags: [RiddleApiTags.RIDDLE_CREATED],
      transformResponse: (response: RiddleAnswerResponse) => {
        return response
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          // Wait for the query to finish
          await queryFulfilled

          // After the query completes successfully, trigger refetch for the approved posts
          dispatch(levelApi.util.invalidateTags([LevelApiTags.LEVEL_CREATED]))

          // After the query completes successfully, trigger refetch for the approved posts
          dispatch(
            mysterApi.util.invalidateTags([MysteryApiTags.MYSTERY_CREATED]),
          )
        } catch (err) {
          console.error('Error refetching:', err)
        }
      },
    }),
  }),
})

export const { useGetRiddleByIdQuery, useSubmitRiddleMutation } = riddleApi
