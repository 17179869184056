import React, { useState, useRef, useEffect } from 'react'
import { Box, Card, Typography, Grid, InputBase, Button } from '@mui/material'

interface NumberInputCardProps {
  question: string
  length: number
  onSubmit: (number: string) => void // Submit handler
}

const NumberInputCard: React.FC<NumberInputCardProps> = ({
  question,
  length,
  onSubmit,
}) => {
  const [number, setNumber] = useState<string[]>(Array(length).fill(''))
  const [isDisabled, setIsDisabled] = useState(true)
  const inputRefs = useRef<(HTMLInputElement | null)[]>(
    Array(length).fill(null),
  )

  const handleChange = (index: number, value: string) => {
    if (/^[0-9]?$/.test(value)) {
      const updatedNumber = [...number]
      updatedNumber[index] = value
      setNumber(updatedNumber)

      // Move to the next input if the value is valid and not empty
      if (value !== '' && index < length - 1) {
        inputRefs.current[index + 1]?.focus()
      }
    }
  }

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Backspace') {
      // If the current input is empty, move focus back to the previous input
      if (number[index] === '' && index > 0) {
        inputRefs.current[index - 1]?.focus()
      }
    }
  }

  useEffect(() => {
    // Check if all fields are filled and enable/disable submit button
    const allFilled = number.every((num) => num !== '')
    setIsDisabled(!allFilled)
  }, [number])

  const handleSubmit = () => {
    // Combine numbers into a single string
    const combinedNumber = number.join('')
    onSubmit(combinedNumber) // Call the onSubmit handler with the combined number
  }

  return (
    <Card
      sx={{
        backgroundColor: '#2E2E2E', // Dark gray for versatility
        padding: '30px 20px',
        borderRadius: '16px',
        maxWidth: '600px',
        margin: '20px auto',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)', // Subtle shadow for depth
        textAlign: 'center',
        border: '2px solid rgba(255, 255, 255, 0.2)', // Light border
      }}
    >
      <Typography
        variant='h5'
        sx={{
          fontWeight: 'bold',
          color: '#00ffc3', // Bright cyan for emphasis
          marginBottom: '24px',
          fontFamily: "'Roboto Slab', serif", // Unique font family
        }}
      >
        {question}
      </Typography>

      <Grid container justifyContent='center' spacing={2}>
        {number.map((num, index) => (
          <Grid item key={index}>
            <Box
              sx={{
                width: '50px',
                height: '60px',
                backgroundColor: '#444', // Darker gray for inputs
                border: '2px solid rgba(255, 255, 255, 0.4)', // Lighter border for contrast
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: '0.3s',
                '&:hover': {
                  backgroundColor: '#555', // Slightly lighter on hover
                },
              }}
            >
              <InputBase
                inputRef={(el) => (inputRefs.current[index] = el)}
                value={num}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) =>
                  handleKeyDown(
                    index,
                    e as React.KeyboardEvent<HTMLInputElement>,
                  )
                }
                inputProps={{
                  maxLength: 1,
                  style: {
                    textAlign: 'center',
                    fontSize: '24px',
                    color: '#00ffc3', // Bright color for the number
                    fontFamily: "'Roboto Mono', monospace",
                    outline: 'none',
                  },
                }}
                sx={{
                  '& input': {
                    textAlign: 'center',
                    padding: '0',
                    border: 'none',
                    background: 'transparent',
                    width: '100%',
                  },
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Submit Button */}
      {!isDisabled && (
        <Box
          sx={{
            marginTop: '30px',
            textAlign: 'center',
          }}
        >
          <Button
            variant='contained'
            onClick={handleSubmit}
            sx={{
              padding: '10px 24px',
              backgroundColor: '#00ffc3',
              color: '#111',
              fontWeight: 'bold',
              borderRadius: '50px',
              textTransform: 'none',
              fontFamily: "'Roboto Mono', monospace",
              fontSize: { xs: '16px', md: '18px' }, // Responsive font size
              boxShadow: '0 0 5px rgba(0, 255, 255, 0.3)',
              visibility: isDisabled ? 'hidden' : 'visible', // Hide when disabled
              '&:hover': {
                backgroundColor: '#00e6b3',
                boxShadow: '0 0 10px rgba(0, 255, 255, 0.5)', // Subtle hover glow
              },
            }}
            disabled={isDisabled} // Still keep the disabled state for logic
          >
            Submit
          </Button>
        </Box>
      )}
    </Card>
  )
}

export default NumberInputCard
