import React from 'react'
import { Box, SvgIcon, useTheme } from '@mui/material'

const CustomLoader = () => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'grid',
        placeItems: 'center',
        height: '100vh',
      }}
    >
      <SvgIcon
        viewBox='0 0 256 128'
        sx={{
          width: '6em', // Decreased width
          height: '3em', // Decreased height
          '& .ip__track': {
            stroke: theme.palette.grey[300],
            transition: 'stroke 0.3s',
          },
          '& .ip__worm1, & .ip__worm2': {
            animation: 'worm1 2s linear infinite',
          },
          '& .ip__worm2': {
            animationName: 'worm2',
          },
          '@media (prefers-color-scheme: dark)': {
            '& .ip__track': {
              stroke: theme.palette.grey[700],
            },
          },
        }}
      >
        <defs>
          <linearGradient id='grad1' x1='0' y1='0' x2='1' y2='0'>
            <stop offset='0%' stopColor='#5ebd3e' />
            <stop offset='33%' stopColor='#ffb900' />
            <stop offset='67%' stopColor='#f78200' />
            <stop offset='100%' stopColor='#e23838' />
          </linearGradient>
          <linearGradient id='grad2' x1='1' y1='0' x2='0' y2='0'>
            <stop offset='0%' stopColor='#e23838' />
            <stop offset='33%' stopColor='#973999' />
            <stop offset='67%' stopColor='#009cdf' />
            <stop offset='100%' stopColor='#5ebd3e' />
          </linearGradient>
        </defs>
        <g fill='none' strokeLinecap='round' strokeWidth='16'>
          <g className='ip__track' stroke={theme.palette.grey[300]}>
            <path d='M8,64s0-56,60-56,60,112,120,112,60-56,60-56' />
            <path d='M248,64s0-56-60-56-60,112-120,112S8,64,8,64' />
          </g>
          <g strokeDasharray='180 656'>
            <path
              className='ip__worm1'
              stroke='url(#grad1)'
              strokeDashoffset='0'
              d='M8,64s0-56,60-56,60,112,120,112,60-56,60-56'
            />
            <path
              className='ip__worm2'
              stroke='url(#grad2)'
              strokeDashoffset='358'
              d='M248,64s0-56-60-56-60,112-120,112S8,64,8,64'
            />
          </g>
        </g>
        <style>{`
          @keyframes worm1 {
            from {
              stroke-dashoffset: 0;
            }
            50% {
              animation-timing-function: steps(1);
              stroke-dashoffset: -358;
            }
            50.01% {
              animation-timing-function: linear;
              stroke-dashoffset: 358;
            }
            to {
              stroke-dashoffset: 0;
            }
          }
          @keyframes worm2 {
            from {
              stroke-dashoffset: 358;
            }
            50% {
              stroke-dashoffset: 0;
            }
            to {
              stroke-dashoffset: -358;
            }
          }
        `}</style>
      </SvgIcon>
    </Box>
  )
}

export default CustomLoader
