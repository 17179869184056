import { Box, Button, Grow, Slide, Typography } from '@mui/material'
import InputField from '../../components/InputField'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from '../../constants/routeUrls'
import useSignupModal from './useSignupModal'
import { RootState } from '../../store'
import { TAuthState } from '../../store/slice/auth/authSlice'
import { useSelector } from 'react-redux'
import {
  formContainerStyle,
  inputStyles,
  primaryButtonStyle,
  secondaryButtonStyle,
} from './styles'

const Signup = () => {
  const navigate = useNavigate()

  // const modalsState: TModalState = useSelector(
  //   (state: any) => state.commonReducer.modals,
  // )

  const authState: TAuthState = useSelector(
    (state: RootState) => state.authReducer,
  )

  // navigate to login page
  const navigateToLogin = () => {
    navigate(routeUrls.LOGIN)
  }

  const { control, handleSubmit, onSubmit } = useSignupModal()

  return (
    <>
      <Box
        sx={{
          ...formContainerStyle,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {/* Form Header */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: { xs: '100%' },
            mb: { xs: 3, sm: 0 },
          }}
        >
          <Grow in={true} timeout={3000}>
            <Typography
              variant='h4'
              sx={{
                color: '#FFD700',
                mb: 2,
                fontFamily: '"Cinzel", serif',
                fontWeight: 700,
                letterSpacing: '1px',
                textShadow: '0 0 15px #FFD700',
              }}
            >
              Start Your Epic Journey!
            </Typography>
          </Grow>

          <Grow in={true} timeout={4000}>
            <Typography
              variant='h6'
              sx={{
                fontSize: { xs: '1rem', sm: '1.2rem' },
                color: '#E0E0E0',
              }}
            >
              Unleash Your Adventure – Join Us Now!
            </Typography>
          </Grow>
        </Box>

        {/* Form Fields */}
        <Box
          component='form'
          noValidate
          width={'100%'}
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Full Name Input */}
          <InputField
            control={control}
            name='fullName'
            placeholder='Name of Our New Hero...'
            fullWidth
            sx={{
              ...inputStyles,
              mb: 1,
              mt: 1,
            }}
          />
          {/* Email Input */}
          <InputField
            control={control}
            name='email'
            placeholder='Your Email for Your Adventure...'
            fullWidth
            sx={{
              ...inputStyles,
              mb: 1,
              mt: 1,
            }}
          />

          {/* Password Input */}
          <InputField
            control={control}
            name='password'
            type='password'
            placeholder='Set Your Magic Password...'
            fullWidth
            sx={{
              ...inputStyles,
              mb: 1,
              mt: 1,
            }}
          />

          {/* Confirm Password Input */}
          <InputField
            control={control}
            name='confirmPassword'
            type='password'
            placeholder='Confirm Your Magic Password...'
            fullWidth
            sx={{
              ...inputStyles,
              mb: 1,
              mt: 1,
            }}
          />

          {/* Call-to-Action Buttons */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 1,
            }}
          >
            <Slide
              direction='up'
              in={true}
              timeout={2000}
              mountOnEnter
              unmountOnExit
            >
              <LoadingButton
                type='submit'
                variant='contained'
                sx={primaryButtonStyle}
                loading={authState.loading === 'pending'}
                fullWidth
              >
                Join the Adventure
              </LoadingButton>
            </Slide>
            <Slide
              direction='up'
              in={true}
              timeout={3000}
              mountOnEnter
              unmountOnExit
            >
              <Button
                onClick={navigateToLogin}
                variant='outlined'
                sx={{
                  ...secondaryButtonStyle,
                  mt: 2,
                }}
                fullWidth
              >
                Already Joined the Journey?
              </Button>
            </Slide>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Signup
