import React, { useState, useEffect } from 'react'
import { Card, Typography, Button } from '@mui/material'

export type NextLevelCardProps = {
  isNextLevelActive: boolean // Prop to check if the next level is available
  handleNavigateNextLevel: () => void // Function to navigate to the next level
  handleNavigateEnd: () => void // Function to navigate when no more levels
}

const NextLevelCard: React.FC<NextLevelCardProps> = ({
  isNextLevelActive,
  handleNavigateNextLevel,
  handleNavigateEnd,
}) => {
  return (
    <Card
      sx={{
        backgroundColor: '#1A1A1A',
        padding: '40px 30px',
        borderRadius: '20px',
        maxWidth: '650px',
        margin: '30px auto',
        boxShadow: '0 0 30px rgba(0, 0, 0, 0.6)',
        textAlign: 'center',
        border: '2px solid rgba(255, 255, 255, 0.3)',
        position: 'relative',
      }}
    >
      <Typography
        variant='h2'
        sx={{
          fontWeight: 'bold',
          color: '#ffec5c',
          marginBottom: '20px',
          fontFamily: "'Lobster', cursive",
          textShadow: '2px 2px 5px rgba(255, 255, 255, 0.5)',
        }}
      >
        🎉 Victory!
      </Typography>

      <Typography
        variant='h5'
        sx={{
          fontWeight: 'bold',
          color: '#00ffc3',
          marginBottom: '30px',
          fontFamily: "'Poppins', sans-serif",
          textTransform: 'uppercase',
          letterSpacing: '0.1em',
        }}
      >
        {isNextLevelActive
          ? 'You’ve completed this level! Well done! The next level is ready!'
          : 'You’ve completed this level! But the next level is locked.'}
      </Typography>

      <Button
        variant='contained'
        onClick={
          isNextLevelActive ? handleNavigateNextLevel : handleNavigateEnd
        }
        sx={{
          padding: '12px 28px',
          backgroundColor: '#ffec5c',
          color: '#333',
          fontWeight: 'bold',
          borderRadius: '50px',
          textTransform: 'none',
          fontFamily: "'Poppins', sans-serif",
          fontSize: { xs: '16px', md: '18px' },
          boxShadow: '0 0 10px rgba(255, 236, 92, 0.6)',
          '&:hover': {
            backgroundColor: '#ffd700',
            boxShadow: '0 0 15px rgba(255, 236, 92, 1)',
          },
        }}
      >
        {isNextLevelActive ? 'Go to Next Level' : 'Back to Home'}
      </Button>
    </Card>
  )
}

export default NextLevelCard
