import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { RootState } from '../store'
import { routeUrls } from '../constants/routeUrls'

const ProtectedRoute: FC = () => {
  const { isAuthenticated, fullName, email, userId } = useSelector(
    (state: RootState) => state.authReducer,
  )
  const location = useLocation()
  if (!isAuthenticated && !!!fullName && !!!email && !!!userId) {
    return <Navigate to={routeUrls.WELCOME_PAGE} state={{ from: location }} />
  }
  return <Outlet />
}

export default ProtectedRoute
