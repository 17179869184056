import React, { FC, useState } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Fade,
  CircularProgress,
  Backdrop,
} from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import FirstRankIconImage from '../../assets/images/first.png'
import SecondRankIconImage from '../../assets/images/second-place.png'
import ThirdRankIconImage from '../../assets/images/third-place.png'
import {
  CustomPngIconProps,
  DisabledTab,
  FirstPlaceRow,
  Header,
  LeaderboardContainer,
  PaginationContainer,
  SecondPlaceRow,
  StyledTab,
  StyledTabs,
  TableCellStyled,
  TableRowStyled,
  ThirdPlaceRow,
  Title,
} from './styles'
import { useGetAllLevelsByMysteryIdQuery } from '../../api/levelApi'
import { useNavigate, useParams } from 'react-router-dom'
import { routeUrls } from '../../constants/routeUrls'
import CustomLoader from '../../components/CustomLoader'
import { TLevels } from '../../api/levelApi/types'
import { useGetAllUserProgressByIdQuery } from '../../api/userprogress'
import BackButton from './BackButton'

// Custom Icon component for ranking icons
const CustomPngIcon: React.FC<CustomPngIconProps> = ({
  src,
  alt = 'Custom Icon',
}) => (
  <img src={src} alt={alt} style={{ width: '30px', height: '30px' }} /> // Set size as needed
)

// Leaderboard Component
const Leaderboard: FC = () => {
  const [tabValue, setTabValue] = useState(1)

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const { mysteryId } = useParams() // Access params
  const navigate = useNavigate()

  if (!mysteryId) {
    navigate(routeUrls.HOME)
  }

  // Fetch levels by mystery id
  const {
    data: levelsList,
    isFetching: isLevelsListFetching,
    isSuccess: isLevelsListSuccess,
  } = useGetAllLevelsByMysteryIdQuery({
    mystery_id: mysteryId,
  })

  // Fetch user progress for the specific level and mystery id
  const {
    data: usersProgressList,
    isFetching: isUsersProgressFetching,
    isSuccess: isUsersProgressSuccess,
  } = useGetAllUserProgressByIdQuery({
    mystery_id: mysteryId,
    level_number: tabValue,
    page: page,
    page_size: pageSize,
  })

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
    setPage(1) // Reset page when switching tabs
  }

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value)
  }

  return (
    <LeaderboardContainer>
      {/* Loading Overlay for the Tabs and Levels List */}
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 2 })}
        open={isLevelsListFetching || !isLevelsListSuccess}
      >
        <CustomLoader />
      </Backdrop>

      <Header>
        <BackButton />
        <Fade in timeout={500}>
          <Title mt={1}>Leaderboard</Title>
        </Fade>
      </Header>

      {/* Tab Navigation */}
      <StyledTabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label='Leaderboard Tabs'
        variant='scrollable'
        scrollButtons='auto'
        TabIndicatorProps={{ style: { display: 'none' } }} // Hide default indicator
      >
        {levelsList?.results?.map((level: TLevels) =>
          level?.is_active ? (
            <StyledTab
              key={level?.level_number}
              label={'Level ' + level?.level_number}
              value={level?.level_number}
            />
          ) : (
            <DisabledTab key={level?.level_number}>
              <LockIcon fontSize='small' />
              {'Level ' + level?.level_number}
            </DisabledTab>
          ),
        )}
      </StyledTabs>

      {/* Loading State for User Progress */}
      {isUsersProgressFetching ? (
        <Box display='flex' justifyContent='center' alignItems='center' mt={3}>
          <CircularProgress color='secondary' />
        </Box>
      ) : (
        <Box>
          <TableContainer
            component={Paper}
            style={{ backgroundColor: '#1C2331' }}
          >
            <Fade in timeout={500}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellStyled>Ranking</TableCellStyled>
                    <TableCellStyled>User ID</TableCellStyled>
                    <TableCellStyled>Username</TableCellStyled>
                    <TableCellStyled>Total Attempts</TableCellStyled>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersProgressList?.results?.length ? (
                    usersProgressList.results.map((user, index) => {
                      const rank = (page - 1) * pageSize + index + 1

                      return rank === 1 ? (
                        <FirstPlaceRow key={user?.user_id}>
                          <TableCellStyled>
                            <CustomPngIcon src={FirstRankIconImage} />
                          </TableCellStyled>
                          <TableCellStyled>{user.user_id}</TableCellStyled>
                          <TableCellStyled>{user.full_name}</TableCellStyled>
                          <TableCellStyled>{user.attempts}</TableCellStyled>
                        </FirstPlaceRow>
                      ) : rank === 2 ? (
                        <SecondPlaceRow key={user?.user_id}>
                          <TableCellStyled>
                            <CustomPngIcon src={SecondRankIconImage} />
                          </TableCellStyled>
                          <TableCellStyled>{user.user_id}</TableCellStyled>
                          <TableCellStyled>{user.full_name}</TableCellStyled>
                          <TableCellStyled>{user.attempts}</TableCellStyled>
                        </SecondPlaceRow>
                      ) : rank === 3 ? (
                        <ThirdPlaceRow key={user?.user_id}>
                          <TableCellStyled>
                            <CustomPngIcon src={ThirdRankIconImage} />
                          </TableCellStyled>
                          <TableCellStyled>{user.user_id}</TableCellStyled>
                          <TableCellStyled>{user.full_name}</TableCellStyled>
                          <TableCellStyled>{user.attempts}</TableCellStyled>
                        </ThirdPlaceRow>
                      ) : (
                        <TableRowStyled key={user?.user_id}>
                          <TableCellStyled sx={{ pl: 3 }}>
                            {rank}
                          </TableCellStyled>
                          <TableCellStyled>{user.user_id}</TableCellStyled>
                          <TableCellStyled>{user.full_name}</TableCellStyled>
                          <TableCellStyled>{user.attempts}</TableCellStyled>
                        </TableRowStyled>
                      )
                    })
                  ) : (
                    <TableRowStyled>
                      <TableCellStyled colSpan={4} align='center'>
                        No Data Available
                      </TableCellStyled>
                    </TableRowStyled>
                  )}
                </TableBody>
              </Table>
            </Fade>
          </TableContainer>
        </Box>
      )}

      {/* Pagination */}
      <PaginationContainer>
        {usersProgressList && (
          <Pagination
            count={Math.ceil((usersProgressList.count || 0) / pageSize)}
            page={page}
            onChange={handlePageChange}
            aria-label='Leaderboard Pagination'
          />
        )}
      </PaginationContainer>
    </LeaderboardContainer>
  )
}

export default Leaderboard
