import { useForm, SubmitHandler } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { SignupForm } from './types'
import { signupValidationSchema } from './validations'
import { yupResolver } from '@hookform/resolvers/yup'
import { AppDispatch, RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { authSignup } from '../../store/slice/auth/authActions'
import { toast, Zoom } from 'react-toastify'
import { TAuthState } from '../../store/slice/auth/authSlice'

const useSignupModal = () => {
  // const modalsState: TModalState = useSelector(
  //   (state: RootState) => state.commonReducer.modals,
  // )

  const authState: TAuthState = useSelector(
    (state: RootState) => state.authReducer,
  )

  const dispatch = useDispatch<AppDispatch>()

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm<SignupForm>({
    defaultValues: {
      fullName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(signupValidationSchema),
  })

  const resetForm = () => {
    reset()
  }

  useEffect(() => {
    if (errors) {
      // Extract the first error message
      const firstErrorMessage = Object.values(errors)[0]?.message || ''

      if (firstErrorMessage) {
        toast.error(firstErrorMessage, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Zoom,
        })
      }
    }
  }, [errors])

  useEffect(() => {
    if (authState.error) {
      toast.error(authState.error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Zoom,
      })
    }
  }, [authState.error])

  const onSubmit: SubmitHandler<SignupForm> = async (data) => {
    const formData = new FormData()

    formData.append('full_name', data?.fullName)
    formData.append('email', data?.email)
    formData.append('password', data.password)

    dispatch(authSignup(formData))
  }

  return {
    control,
    register,
    errors,
    reset: resetForm,
    onSubmit,
    handleSubmit,
    watch,
  }
}

export default useSignupModal
