import React from 'react'
import { Box, Typography, Tooltip, IconButton } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import Grid from '@mui/material/Grid2'
import {
  ContactForm,
  Footer,
  Header,
  inputStyles,
  SubmitButton,
  Subtitle,
  Title,
} from './styles'
import InputField from '../../components/InputField'
import useContactUsModal from './useContactUsModal'
import SubjectIcon from '@mui/icons-material/Subject'

const ContactUs: React.FC = () => {
  const { control, loading, handleSubmit, onSubmit } = useContactUsModal()
  return (
    <Box
      sx={{
        padding: 3,
        color: '#D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      {/* Header Section */}
      <Header>
        <Title>Contact Us</Title>
        <Subtitle>
          We’d love to hear from you! Fill out the form below.
        </Subtitle>
      </Header>

      {/* Contact Form */}
      <ContactForm>
        {/* Form Fields */}
        <Box
          component='form'
          noValidate
          width={'100%'}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={2}>
            {/* Subject Field */}
            <Grid size={12}>
              {/* Message */}
              <InputField
                control={control}
                name='subject'
                label='Subject'
                placeholder='Enter the subject here'
                fullWidth
                sx={{
                  ...inputStyles,
                  mb: 1,
                  mt: 1,
                }}
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <SubjectIcon sx={{ color: '#FFD700' }} />
                    </IconButton>
                  ),
                }}
              />
            </Grid>

            {/* Message Field */}
            <Grid size={12}>
              <InputField
                control={control}
                name='message'
                label='Message'
                placeholder='Write your message here'
                fullWidth
                sx={{
                  ...inputStyles,
                  mb: 1,
                  mt: 1,
                }}
                multiline
                rows={4}
              />
            </Grid>

            {/* Submit Button */}
            <Grid size={12}>
              <SubmitButton
                fullWidth
                variant='contained'
                endIcon={<SendIcon />}
                loading={loading}
                type='submit'
              >
                Send Message
              </SubmitButton>
            </Grid>
          </Grid>
        </Box>
      </ContactForm>

      {/* Footer Section */}
      <Footer>
        <Typography variant='body2'>
          Thank you for reaching out! We aim to respond within 48 hours.
        </Typography>
      </Footer>
    </Box>
  )
}

export default ContactUs
