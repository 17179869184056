import { Card, Typography, Box, Avatar, Stack, Divider } from '@mui/material'
import { InfoText, NameText, ProfileAvatar, StyledCard } from './styles'
import { ProfileInfoCardProps } from './types'
import ProfileImage from '../../../assets/images/profile.png'

// ProfileInfoCard Component
const ProfileInfoCard: React.FC<ProfileInfoCardProps> = ({
  userName,
  userEmail,
  userId,
  memberSince,
}) => {
  return (
    <StyledCard>
      {/* Profile Image */}
      <ProfileAvatar src={ProfileImage} alt='Profile Image' />

      {/* User Information */}
      <NameText variant='h4'>{userName}</NameText>
      <InfoText variant='body2'>User ID: {userId}</InfoText>
      <InfoText variant='body2'>{userEmail}</InfoText>

      <Divider sx={{ my: 2, backgroundColor: '#444' }} />

      <Stack direction='row' justifyContent='space-between' mb={2}>
        <InfoText>Member Since:</InfoText>
        <Typography>{memberSince}</Typography>
      </Stack>
    </StyledCard>
  )
}

export default ProfileInfoCard
