import { useForm, SubmitHandler } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { ILoginForm } from './types'
import { loginValidationSchema } from './validations'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import { authlogin, authSignup } from '../../store/slice/auth/authActions'
import { AppDispatch, RootState } from '../../store'
import { setModalsData, TModalState } from '../../store/slice/commonSlice'
import { TAuthState } from '../../store/slice/auth/authSlice'
import { toast, Zoom } from 'react-toastify'
import { toastErrorMessage } from '../../utils/toastMessage'

const useLogin = () => {
  const modalsState: TModalState = useSelector(
    (state: RootState) => state.commonReducer.modals,
  )

  const authState: TAuthState = useSelector(
    (state: RootState) => state.authReducer,
  )
  const dispatch = useDispatch<AppDispatch>()

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<ILoginForm>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(loginValidationSchema),
  })

  useEffect(() => {
    if (errors) {
      // Extract the first error message
      const firstErrorMessage = Object.values(errors)[0]?.message || ''

      if (firstErrorMessage) {
        toastErrorMessage(firstErrorMessage)
      }
    }
  }, [errors])

  useEffect(() => {
    if (authState.error) {
      toastErrorMessage(authState.error)
    }
  }, [authState.error])

  const onSubmit: SubmitHandler<ILoginForm> = async (data) => {
    const formData = new FormData()

    formData.append('email', data?.email)
    formData.append('password', data.password)

    dispatch(authlogin(formData))
  }

  return {
    control,
    register,
    errors,
    onSubmit,
    handleSubmit,
    watch,
  }
}

export default useLogin
