import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  SpeedDial,
  SpeedDialAction,
} from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import ContactPageIcon from '@mui/icons-material/ContactPage'
import HelpIcon from '@mui/icons-material/Help'
import LogoutIcon from '@mui/icons-material/Logout'

import key from '../../../assets/gif/keys.gif'
import keyOpen from '../../../assets/gif/keysOpen.gif'
import { routeUrls } from '../../../constants/routeUrls'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { authlogout, resetState } from '../../../store/slice/auth/authSlice'

const SpeedDialComponent = () => {
  const navigate = useNavigate()
  const [hovered, setHovered] = useState(false)
  const [isGifLoaded, setIsGifLoaded] = useState(false) // Track GIF load state

  const handleNavigation = (path: any) => {
    navigate(path)
  }

  const dispatch = useDispatch()

  // Navigation handlers
  const handleHomeActionOnClick = () => navigate(routeUrls.HOME)
  const handleProfileActionOnClick = () => navigate(routeUrls.PROFILE)
  const handleHelpActionOnClick = () => navigate(routeUrls.HELP)
  const handleLogoutActionOnClick = () => {
    dispatch(authlogout())
    navigate(routeUrls.WELCOME_PAGE)
  }

  const handleContactUsOnClick = () => navigate(routeUrls.CONTACTUS)

  const actions = [
    { icon: HomeIcon, name: 'Home', onclick: handleHomeActionOnClick },
    { icon: PersonIcon, name: 'Profile', onclick: handleProfileActionOnClick },
    { icon: HelpIcon, name: 'Help', onclick: handleHelpActionOnClick },
    {
      icon: ContactPageIcon,
      name: 'Contact Us',
      onclick: handleContactUsOnClick,
    },
    { icon: LogoutIcon, name: 'Exit', onclick: handleLogoutActionOnClick },
  ]

  useEffect(() => {
    const loadGif = (src: string) => {
      const img = new Image()
      img.src = src
      img.onload = () => {
        setIsGifLoaded(true)
      }
    }

    // Preload the GIFs
    loadGif(key)
    loadGif(keyOpen)
  }, [])

  return (
    <SpeedDial
      ariaLabel='Speed Dial'
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
      }}
      icon={
        <div
          style={{
            position: 'relative',
            borderRadius: 'inherit',
            width: '60px',
            height: '60px',
          }}
        >
          {!isGifLoaded ? (
            <CircularProgress
              size={58}
              style={{
                position: 'relative',
                color: 'white',
                transform: 'translate(-50%, -50%)',
              }}
            />
          ) : (
            <>
              <img
                src={key} // Default GIF
                alt='default icon'
                style={{
                  width: '60px',
                  height: '60px',
                  position: 'absolute',
                  borderRadius: 'inherit',
                  top: 0,
                  left: 0,
                  opacity: hovered ? 0 : 1,
                  transition: 'opacity 0.3s ease-in-out', // Faster transition
                }}
              />
              <img
                src={keyOpen} // Hover GIF
                alt='hover icon'
                style={{
                  width: '60px',
                  height: '60px',
                  position: 'absolute',
                  borderRadius: 'inherit',
                  top: 0,
                  left: 0,
                  opacity: hovered ? 1 : 0,
                  transition: 'opacity 0.3s ease-in-out', // Faster transition
                }}
              />
            </>
          )}
        </div>
      }
      onMouseEnter={() => setHovered(true)} // Smooth transition on hover
      onMouseLeave={() => setHovered(false)} // Smooth transition on hover out
    >
      {actions.map((action, index) => {
        const Icon = action.icon

        return (
          <SpeedDialAction
            key={index}
            icon={<Icon />}
            tooltipTitle={action.name}
            onClick={action.onclick}
          />
        )
      })}
    </SpeedDial>
  )
}

export default SpeedDialComponent
