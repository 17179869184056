export const API_ENDPOINTS = {
  LOGIN: '/users/login.php',
  SIGNUP: '/users/user.php',

  SINGLE_PROFILE: '/users/user.php',

  GET_ALL_MYSTERY: '/mystery/mystery.php',

  SEND_MESSAGE: '/contactus/contactus.php',

  GET_LEVEL_BY_ID: '/level/level.php',
  GET_ALL_LEVELS_BY_MYSTERY_ID: '/level/level.php',

  GET_RIDDLE_BY_ID: '/riddle/riddle.php',

  SEND_RIDDLE_ANSWER: '/riddle/submit_riddle.php',

  GET_ALL_USERPROGRESS_BY_ID: '/userprogress/userprogress.php',
}
