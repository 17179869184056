import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS } from '../../constants/apiendpoints'
import { API_METHODS } from '../../constants/constants'
import { ContactUsResponse } from './types'

export const contactusApi = createApi({
  reducerPath: 'contactusApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  endpoints: (builder) => ({
    // Send Message
    sendMessage: builder.mutation<ContactUsResponse, FormData>({
      query: (params: FormData) => ({
        url: API_ENDPOINTS.SEND_MESSAGE,
        method: API_METHODS.POST,
        body: params,
      }),
      transformResponse: (response: ContactUsResponse) => {
        return response
      },
    }),
  }),
})

export const { useSendMessageMutation } = contactusApi
