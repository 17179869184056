import { Card, Typography, Avatar, styled } from '@mui/material'

// Styled Card with new theme
export const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#2E3445', // Dark background color
  padding: theme.spacing(3),
  borderRadius: '10px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
  width: '100%',
  maxWidth: '600px', // Set a max width for the card
  margin: '0 auto',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2), // Responsive padding for small screens
  },
}))

// Styled Profile Avatar
export const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  margin: '0 auto',
  borderRadius: '50%',
  border: '4px solid #444', // Dark border
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.6)', // Darker shadow
}))

// Styled Name Text
export const NameText = styled(Typography)(({ theme }) => ({
  fontWeight: '700',
  fontSize: '1.6rem',
  color: '#FFD700', // Golden color
  marginTop: '1rem',
}))

// Styled Info Text
export const InfoText = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: '#ccc', // Light gray for supporting text
  marginBottom: '0.5rem',
}))
