import { configureStore } from '@reduxjs/toolkit'
import authSlice from './slice/auth/authSlice'
import commonSlice from './slice/commonSlice'
import { profileApi } from '../api/profileApi'
import { mysterApi } from '../api/mysteryApi'
import { contactusApi } from '../api/contactusApi'
import { levelApi } from '../api/levelApi'
import { riddleApi } from '../api/riddleApi'
import { userProgressApi } from '../api/userprogress'

const store = configureStore({
  reducer: {
    commonReducer: commonSlice,
    authReducer: authSlice,
    [profileApi.reducerPath]: profileApi.reducer,
    [mysterApi.reducerPath]: mysterApi.reducer,
    [contactusApi.reducerPath]: contactusApi.reducer,
    [levelApi.reducerPath]: levelApi.reducer,
    [riddleApi.reducerPath]: riddleApi.reducer,
    [userProgressApi.reducerPath]: userProgressApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      profileApi.middleware,
      mysterApi.middleware,
      contactusApi.middleware,
      levelApi.middleware,
      riddleApi.middleware,
      userProgressApi.middleware,
    ),
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export default store
