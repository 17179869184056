import { useNavigate } from 'react-router-dom'
import { routeUrls } from '../../constants/routeUrls'

const useHomeHook = () => {
  const navigate = useNavigate()

  const handleSubmit = (mysteryId: string | number, level: string | number) => {
    navigate(routeUrls.LEVEL + '/' + mysteryId + '/' + level)
  }

  return {
    handleSubmit,
  }
}

export default useHomeHook
