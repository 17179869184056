import { useNavigate, useParams } from 'react-router-dom'
import { routeUrls } from '../../constants/routeUrls'
import {
  Backdrop,
  Box,
  Fab,
  Fade,
  Grid,
  Grow,
  IconButton,
  styled,
  Typography,
} from '@mui/material'
import { useGetLevelByIdQuery } from '../../api/levelApi'
import BackgroundImage from './BackgroundImage'
import BackButton from './BackButton'
import NumberInputCard from '../../components/NumberInputCard'
import TextInputCard from '../../components/TextInputCard'
import CustomLoader from '../../components/CustomLoader'
import { useGetRiddleByIdQuery } from '../../api/riddleApi'
import useLevelHook from './useLevelHook'
import NextLevelCard from './NextLevelCard'
import MysteryCompletedCard from './MysteryCompletedCard'
import Confetti from 'react-confetti'
import { useEffect, useState } from 'react'
import { TAuthState } from '../../store/slice/auth/authSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import YouTubeIcon from '@mui/icons-material/YouTube'
import './constants/styles.css'
import { CustomPngIconProps } from '../Leaderboard/styles'

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: '#FFD700',
  textShadow: '0 0 8px rgba(255, 215, 0, 0.8)',
  fontFamily: '"Cinzel", serif',
  fontSize: '3rem', // default font size
  marginTop: '1rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem', // smaller font size for screens smaller than 'md'
    marginTop: '1.5rem',
  },
}))

const Level = () => {
  const { mysteryId, level } = useParams() // Access params
  const navigate = useNavigate()

  const authState: TAuthState = useSelector(
    (state: RootState) => state.authReducer,
  )

  // Navigate to home if params are missing
  if (!mysteryId || !level) {
    navigate(routeUrls.HOME)
  }

  const {
    data: levelData,
    isFetching: isLevelFetching,
    isSuccess: isLevelSuccess,
  } = useGetLevelByIdQuery({
    user_id: authState?.userId,
    mystery_id: mysteryId,
    level_number: level,
  })

  const {
    data: riddleData,
    isFetching: isRiddleFetching,
    isSuccess: isRiddleSuccess,
  } = useGetRiddleByIdQuery({
    mystery_id: mysteryId,
    level_number: level,
  })

  // Check if either the level or riddle data is still fetching
  const isLevelLoading = isLevelFetching || isRiddleFetching

  // level hook
  const { onSubmit, isLoading } = useLevelHook({
    riddle_id: riddleData?.result?.riddle_id,
  })

  // handleNextLevel
  const handleNextLevel = () => {
    setShowConfetti(false) // Reset confetti before navigation
    navigate(
      routeUrls.LEVEL +
        '/' +
        mysteryId +
        '/' +
        (levelData?.result?.next_level_number || level),
    )
  }

  const handleNavigateHome = () => {
    navigate(routeUrls.HOME)
  }

  const [showConfetti, setShowConfetti] = useState(false)

  useEffect(() => {
    if (
      levelData?.result?.is_completed ||
      levelData?.result?.is_mystery_completed
    ) {
      setShowConfetti(true)
      const timer = setTimeout(() => {
        setShowConfetti(false)
      }, 10000)
      return () => clearTimeout(timer)
    }
  }, [
    levelData?.result?.is_completed || levelData?.result?.is_mystery_completed,
  ])

  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 2 })}
        open={isLoading || isLevelLoading}
      >
        <CustomLoader />
      </Backdrop>

      {/* Confetti will only show when the level is completed */}
      {showConfetti && (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      )}

      <Box>
        {isLevelSuccess ? (
          <BackgroundImage image={levelData?.result?.image_url} />
        ) : null}

        <Grid container>
          <Grid item xs={2}>
            <BackButton />
          </Grid>
          <Grid item xs={8}>
            <Title textAlign={'center'}>
              {levelData?.result?.level_name || 'Level'}
            </Title>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '80vh',
            padding: '20px',
          }}
        >
          {/* Riddle logic */}
          {isRiddleSuccess &&
            !levelData?.result?.is_completed &&
            !levelData?.result?.is_mystery_completed &&
            riddleData?.result?.answer_type &&
            (riddleData?.result?.answer_type === 'number' ? (
              <NumberInputCard
                question={riddleData?.result?.question}
                length={riddleData?.result?.answer_length} // Use the answer length from riddle data
                onSubmit={(number) => onSubmit(number)}
              />
            ) : (
              <TextInputCard
                length={riddleData?.result?.answer_length} // Use the answer length from riddle data
                question={riddleData?.result?.question}
                onSubmit={(text) => onSubmit(text)}
              />
            ))}

          {/* Show the completion card when the level is completed */}
          {levelData?.result?.is_mystery_completed && <MysteryCompletedCard />}

          {/* Uncomment if NextLevelCard is needed */}
          {!levelData?.result?.is_mystery_completed &&
            levelData?.result?.is_completed && (
              <NextLevelCard
                isNextLevelActive={levelData?.result?.is_next_level_active}
                handleNavigateNextLevel={handleNextLevel}
                handleNavigateEnd={handleNavigateHome}
              />
            )}

          <Box>
            <Grow in={true} timeout={2000}>
              <Fab
                sx={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                }}
                variant='extended'
                color='primary'
                href={levelData?.result?.youtube_url || ''}
                target='_blank'
              >
                <YouTubeIcon fontSize='large' sx={{ mr: 1 }} />
                Hint
              </Fab>
            </Grow>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Level
